import { useContext, useEffect } from "react";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";
import { FindMaterial } from "../../Common/Utils/ThreeTools";
import { FrontSide, DoubleSide } from "three";

export default function YogaLeggings(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/yoga-leggings.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    useEffect(() => {
        // Enable see-through for inner material
        let mat = FindMaterial("out", elements)
        if(mat) {
            mat.side = DoubleSide
        }

        mat = FindMaterial("in", elements)
        if(mat) {
            mat.side = FrontSide
            mat.transparent = true
            mat.opacity = 0.8
        }
    }, [elements])
    
    // Apply lab texture
    useEffect(() => {
        let mat = FindMaterial('out', elements)
        if(mat) {
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}