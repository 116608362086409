import { createAjaxAction } from "../../Common/_actions/AjaxAction";
import { UserGoalPayload } from "../_stores/UserGoalStore";
import { EditUserGoal, EditManyUserGoals, RemoveUserGoal } from "./UserGoalStoreActions";

export enum UserGoalActionType {
  FetchUserGoals = 'USER_GOAL_FETCH_USER_GOALS',
}

export const FetchUserGoals = () => createAjaxAction<{goals: Record<string, UserGoalPayload>}>(UserGoalActionType.FetchUserGoals, {
	url: 'ajax/api/user/goals',
	method: 'GET',
	preventDuplicates: true,
	onSuccess: (response, dispatch) => {
		dispatch(EditManyUserGoals(response.data.goals));
	},
});