import { useContext, useEffect } from "react"
import { Color } from "three"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function ZipperCarryAll(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/zipper-carry-all.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    const option_color = props.options.get('zipper-color') ?? 'nickel'
    useEffect(() => {
        if(!elements
            || !elements[1]?.material
            || !elements[2]?.material
            || !elements[3]?.material
            || !elements[5]?.material
            || !elements[6]?.material
            || !elements[7]?.material
            || !elements[8]?.material
        ) return

        switch (option_color) {
            case "nickel":
                elements[1].material.color = new Color(0x999999)
                elements[2].material.color = new Color(0x858585)
                elements[3].material.color = new Color(0x12120D)
                elements[5].material.color = new Color(0x1a1919)
                elements[6].material.color = new Color(0x999999)
                elements[7].material.color = new Color(0x858585)
                elements[8].material.color = new Color(0x12120D)
                break
            case "brass":
                elements[1].material.color = new Color(0xD5B27C)
                elements[2].material.color = new Color(0x99705C)
                elements[3].material.color = new Color(0x7B4A30)
                elements[5].material.color = new Color(0xffffff)
                elements[6].material.color = new Color(0xD5B27C)
                elements[7].material.color = new Color(0x99705C)
                elements[8].material.color = new Color(0x7B4A30)
                break
        }
    }, [option_color])

    const option_pull = props.options.get('zipper-pull')
    useEffect(() => {
        if(!elements 
            || !elements[6]?.material
            || !elements[7]?.material
            || !elements[8]?.material
        ) return

        elements[6].visible = option_pull != "no-pull"
        elements[7].visible = option_pull != "no-pull"
        elements[8].visible = option_pull != "no-pull"
    }, [option_pull])
    
    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[0]?.material) return

        elements[0].material.map = productTexture
        elements[0].material.map.flipY = false
        elements[0].material.map.needsUpdate = true
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}