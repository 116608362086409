import * as Immutable from 'immutable';
import ProductStore from './ProductStore';
import ProductCategoryStore from './ProductCategoryStore';
import ViewProductStore from './ViewProductStore';
import ViewCategoryStore from './ViewCategoryStore';
import ViewProductAttributeTypeStore from './ViewProductAttributeTypeStore';
import ViewProductAttributeValueStore from './ViewProductAttributeValueStore';
import ProductSubCategoryStore from './ProductSubCategoryStore';
import ShippingTypeStore from './ShippingTypeStore';
import StoreIntegrationStore from './StoreIntegration';
import BrandingOptionTypeStore from './BrandingOptionTypeStore';
import GoalStore from './GoalStore';
import GoalCategoryStore from './GoalCategoryStore';
import RewardStore from './RewardStore';

interface IProvince {
	id: number
	name: string
	alpha2_code: string
}

const defaultProvince = Immutable.Record<IProvince>({
	id: null,
	name: '',
	alpha2_code: '',
});

export class Province extends defaultProvince implements IProvince {};

interface ICountry {
	id: number
	name: string
	alpha2_code: string
	alpha3_code: string
	provinces: Immutable.OrderedMap<number, Province>
}

const defaultCountry = Immutable.Record<ICountry>({
	id: null,
	name: '',
	alpha2_code: '',
	alpha3_code: '',
	provinces: Immutable.OrderedMap<number, Province>(),
});

export class Country extends defaultCountry implements ICountry {}

export interface IAppDataStore {
	countries: Immutable.OrderedMap<string, Country>,
	productCategories: Immutable.OrderedMap<string, ProductCategoryStore>,
	productSubCategories: Immutable.OrderedMap<string, ProductSubCategoryStore>,
	products: Immutable.OrderedMap<string, ProductStore>,
	viewProductAttributeValues: Immutable.OrderedMap<string, ViewProductAttributeValueStore>,
	viewProductAttributeTypes: Immutable.OrderedMap<string, ViewProductAttributeTypeStore>,
	viewCategories: Immutable.OrderedMap<string, ViewCategoryStore>,
	viewProducts: Immutable.OrderedMap<string, ViewProductStore>,
	shippingTypes: Immutable.OrderedMap<string, ShippingTypeStore>,
	storeIntegrations: Immutable.OrderedMap<string, StoreIntegrationStore>,
	brandingOptionTypes: Immutable.OrderedMap<string, BrandingOptionTypeStore>,
	goals: Immutable.OrderedMap<string, GoalStore>,
	goalCategories: Immutable.OrderedMap<string, GoalCategoryStore>,
	rewards: Immutable.OrderedMap<string, RewardStore>,
}

const defaultStore = Immutable.Record<IAppDataStore>({
	countries: Immutable.OrderedMap<string, Country>(),
	productCategories: Immutable.OrderedMap<string, ProductCategoryStore>(),
	productSubCategories: Immutable.OrderedMap<string, ProductSubCategoryStore>(),
	products: Immutable.OrderedMap<string, ProductStore>(),
	viewProductAttributeValues: Immutable.OrderedMap<string, ViewProductAttributeValueStore>(),
	viewProductAttributeTypes: Immutable.OrderedMap<string, ViewProductAttributeTypeStore>(),
	viewCategories: Immutable.OrderedMap<string, ViewCategoryStore>(),
	viewProducts: Immutable.OrderedMap<string, ViewProductStore>(),
	shippingTypes: Immutable.OrderedMap<string, ShippingTypeStore>(),
	storeIntegrations: Immutable.OrderedMap<string, StoreIntegrationStore>(),
	brandingOptionTypes: Immutable.OrderedMap<string, BrandingOptionTypeStore>(),
	goals: Immutable.OrderedMap<string, GoalStore>(),
	goalCategories: Immutable.OrderedMap<string, GoalCategoryStore>(),
	rewards: Immutable.OrderedMap<string, RewardStore>(),
});

export default class AppDataStore extends defaultStore implements IAppDataStore {}