import * as Immutable from 'immutable';
import { createAction } from '../../Common/_actions/Action';
import VariantPriceStore from '../../Dashboard/_stores/VariantPriceStore';
import { createAjaxAction, AjaxResponse, AjaxCallback } from '../../Common/_actions/AjaxAction';
import { Dispatch } from "redux";
import { EditUserStorePresets } from '../../UserData/_actions/UserStorePresetActions';
import { Toast } from '../../UIData/_actions/UIDataActions';
import i18next from 'i18next';
import PriceStore from '../../UIData/_stores/PriceStore';
import { UserStorePresetPayload } from '../../UserData/_stores/UserStorePresetStore';

export enum DashboardActionType {
    EditProductListing = "DASHBOARD_EDIT_PRODUCT_LISTING",
    RemoveProductListing = "DASHBOARD_REMOVE_PRODUCT_LISTING",
    SelectProductListing = "DASHBOARD_SELECT_PRODUCT_LISTING",
    ResetProductListings = "DASHBOARD_RESET_PRODUCT_LISTINGS",

	PublishProductListings = 'DASHBOARD_PUBLISH_PRODUCT_LISTINGS',
	SaveStorePreset = 'DASHBOARD_SAVE_STORE_PRESET',
	FetchPurchaseReportData = 'DASHBOARD_FETCH_PURCHASE_REPORT_DATA',
}

export const EditProductListing = (
	id_product_design: number, 
	id_product: number,
	title: string,
	description: string,
	price: PriceStore,
	update_preset: boolean,
	is_modified: boolean,
	disabled_preview_ids?: Array<number>,
	disabled_option_ids?: Array<number>,
	variant_prices?: Immutable.Map<string, VariantPriceStore>,
	squarespace_store_page_id?: number,

) => createAction(DashboardActionType.EditProductListing, {
	id_product_design: id_product_design,
	id_product: id_product,
	title: title,
	description: description,
	price: price,
	update_preset: update_preset,
	is_modified: is_modified,
	disabled_preview_ids: disabled_preview_ids,
	disabled_option_ids: disabled_option_ids,
	variant_prices: variant_prices,
	squarespace_store_page_id: squarespace_store_page_id,
});

export const RemoveProductListing = (id: string) => createAction(DashboardActionType.RemoveProductListing, {
	id: id
});

export const SelectProductListing = (id: string) => createAction(DashboardActionType.SelectProductListing, {
	id: id
});

export const ResetProductListings = () => createAction(DashboardActionType.ResetProductListings, {});

export const PublishProductListings = (store: string, jsonData: string, successCallback?: AjaxCallback) => createAjaxAction(DashboardActionType.PublishProductListings, {
	url: "ajax/d/"+store+"/add-products",
	method: "POST",
	data: jsonData,
	onSuccess: (response, dispatch) => {
		successCallback && successCallback(response, dispatch);
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error)
			dispatch(Toast(i18next.t(response.data.error)));
		else {
			dispatch(Toast(i18next.t('Something went wrong!')));
		}
	},
});

export const SaveStorePreset = (store: string, jsonData: string, successCallback?: AjaxCallback) => createAjaxAction<{storePreset:UserStorePresetPayload}>(DashboardActionType.SaveStorePreset, {
	url: "ajax/d/"+store+"/preset",
	method: "POST",
	data: jsonData,
	onSuccess: (response, dispatch) => {
		dispatch(EditUserStorePresets([response.data.storePreset]));

		successCallback && successCallback(response, dispatch);
	},
	onFailure: (response, dispatch) => {
		if(response && response.data && response.data.error)
			dispatch(Toast(i18next.t(response.data.error)));
		else {
			dispatch(Toast(i18next.t('Something went wrong!')));
		}
	},
});


export const FetchPurchaseReportData = (store: string, startDate: string, endDate:string) => createAjaxAction<{report:Record<string,string>}>(DashboardActionType.FetchPurchaseReportData, {
	url: "ajax/d/"+store+"/purchase-report-data",
	method: "GET",
	data: {
		startDate: startDate,
		endDate: endDate
	},
	onSuccess: (response, dispatch) => {
		//TODO: This is currently handled manually with callbacks in the component, but it should be modified to use the store
	},
});

export type DashboardAction = 
	ReturnType<typeof EditProductListing> |
	ReturnType<typeof RemoveProductListing> |
	ReturnType<typeof SelectProductListing> |
	ReturnType<typeof ResetProductListings>