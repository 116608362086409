import { Stage } from '@react-three/drei';
import { memo, useCallback } from 'react';
import { Object3D, Object3DEventMap } from 'three';
import useRenderingTrace from '../Common/_hooks/useRenderingTrace';

export interface ModelElementsProps {
    elements?: Object3D[]
    forceUpdate?: string
}

const environmentUrl = new URL('@resources/img/ModelViewer/environment.hdr', import.meta.url);

export default memo(function ModelElements(props: ModelElementsProps) {
    const elements = props.elements || []

    const renderElement = useCallback((object:Object3D<Object3DEventMap>) => {
        return <primitive key={object.id} object={object} />
    }, [])

    return <Stage
        key={props.forceUpdate}
        environment={{files: environmentUrl.href}}
        intensity={0.8}
        shadows={{ type: 'accumulative', color: 'white', alphaTest: 0.2, opacity: 0.5, normalBias: 0.02 }}
        adjustCamera={false}
    >
        {elements.map(renderElement)}
    </Stage>
})