import * as Immutable from 'immutable';
import UserGoalStore, { UserGoalPayload } from '../_stores/UserGoalStore';
import { UserGoalStoreAction, UserGoalStoreActionType } from '../_actions/UserGoalStoreActions';

const editUserGoal = (store: Immutable.OrderedMap<string, UserGoalStore>, action: UserGoalPayload) => {
	return store.withMutations(store => {
		const oldUserGoal = store.get(String(action.id_goal));

		const { ...otherProps } = action;

		let newUserGoal = new UserGoalStore(otherProps);

		if (oldUserGoal) {
			newUserGoal = oldUserGoal.mergeDeep(newUserGoal);
		}

		store.set(String(action.id_goal), newUserGoal);
	})
}

const UserGoalReducer = (
	store: Immutable.OrderedMap<string, UserGoalStore> = Immutable.OrderedMap<string, UserGoalStore>(),
	action: UserGoalStoreAction
): Immutable.OrderedMap<string, UserGoalStore> => {
	switch (action.type) {
		case UserGoalStoreActionType.EditUserGoal:
			store = editUserGoal(store, action.userGoal);
			break;

		case UserGoalStoreActionType.EditManyUserGoals:
			Object.values(action.userGoals).forEach(userGoal => {
				store = editUserGoal(store, userGoal);
			})
			break;

		case UserGoalStoreActionType.RemoveUserGoal:
			store = store.remove(String(action.id));
			break;
	}
	return store;
}

export default UserGoalReducer;
