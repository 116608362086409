import * as Immutable from 'immutable';
import GoalToastStore from './GoalToastStore';

export interface IToast {
	key: string
	message: string
	new: boolean
	goalData: GoalToastStore|null
}

const defaultStore = Immutable.Record<IToast>({
	key: '',
	message: "",
	new: false,
	goalData: null,
});

export default class UIToastStore extends defaultStore implements IToast {}