import Immutable from "immutable";

export type GoalToastPayload = {
	id_goal: number,
	count: number,
	total: number,
}

interface IGoalToastStore {
	id_goal: number,
	count: number,
	total: number,
}

const defaultGoalToastStore = Immutable.Record<IGoalToastStore>({
  id_goal: 0,
  count: 0,
  total: 0,
});

export default class GoalToastStore extends defaultGoalToastStore implements IGoalToastStore {}