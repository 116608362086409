import { useContext, useEffect } from "react"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { FindMaterial } from "../../Common/Utils/ThreeTools"

export default function StainlessSteelWaterBottle(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/stainless-steel-water-bottle.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Handle Finish
    const option_finish = props.options.get('finish') ?? 'matte'
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial('print', elements)
        if (!mat) return
        mat.roughness = option_finish === 'glossy' ? 0.1 : 0.7
        mat.metalness = option_finish === 'glossy' ? 0.1 : 0.1
    }, [elements, option_finish])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial('print', elements)
        if (!mat) return
        mat.map = productTexture
        mat.map.flipY = false
        mat.map.needsUpdate = true
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}