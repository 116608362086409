import { useCallback, useContext, useEffect } from "react"
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { DoubleSide, FrontSide } from "three"

export default function FittedTanktop(props: ProductModelProps) {
    const option_productStyle = props.options.get('product-style') ?? 'regular'

    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_productStyle ? {
            'regular': new URL('@resources/3d-models/glb/fitted-tank-top-regular.glb', import.meta.url),
            'long': new URL('@resources/3d-models/glb/fitted-tank-top-long.glb', import.meta.url),
        }[option_productStyle] : undefined
    }, [option_productStyle])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Hide Stretcher
    useEffect(() => {
        let model = FindObject("stretcher", elements)
        if (model) model.visible = false
    }, [elements])

    useEffect(() => {
        // Enable see-through for inner material
        let mat = FindMaterial("out_front", elements)
        if(mat) {
            mat.side = DoubleSide
        }

        mat = FindMaterial("out_back", elements)
        if(mat) {
            mat.side = DoubleSide
        }

        mat = FindMaterial("in_front", elements)
        if(mat) {
            mat.side = FrontSide
            mat.transparent = true
            mat.opacity = 0.8
        }

        mat = FindMaterial("in_back", elements)
        if(mat) {
            mat.side = FrontSide
            mat.transparent = true
            mat.opacity = 0.8
        }
    }, [elements])
    
    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        
        let mat = FindMaterial("out_front", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }

        mat = FindMaterial("out_back", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}