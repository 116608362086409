import Immutable from "immutable";
import { DateTime } from "luxon";

export interface UserRewardPayload {
	id: number;
	id_reward: number;
	code: string;
	used: boolean;
	date_earned: string;
	date_used: string;
}

export interface IUserRewardStore {
	id: number;
	id_reward: number;
	code: string;
	used: boolean;
	date_earned: string;
	date_used: string;
}

const defaultUserRewardStore = Immutable.Record<IUserRewardStore>({
	id: 0,
	id_reward: 0,
	code: '',
	used: false,
	date_earned: DateTime.now().toISO(),
	date_used: DateTime.now().toISO(),
});

export default class UserRewardStore extends defaultUserRewardStore implements IUserRewardStore {}