import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../../Common/_components/CheckBoxes';

const PRODUCT_ID = 130;
const VIEW_PRODUCT_ID = 152;

export default function StainlessSteelWaterBottlePage() {
  const [t] = useTranslation('page/products/stainless-steel-water-bottles');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Stainless Steel Water Bottles | Products')}
      description={t('Design and create your own custom printed Stainless Steel Water Bottles featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#8facfd"
      bgStyle={{
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '42%',
      }}
      tag={t('The all-over print')}
      title={t('Stainless Steel Water Bottle')}
      text={t('Stay hydrated in style with our custom printed stainless steel water bottles! These 24 oz water bottles are printed on-demand using UV technology for vibrant, long-lasting designs. They feature a sturdy plastic lid with a twist-off cap and rigid carrying loop for easy transport. Durable and sleek, water bottles are the perfect companion for everyday adventures.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/stainless-steel-water-bottles/banner-1-' + locale + '.png')}
      alt={t('Picture of Stainless Steel Water Bottle')}
      details={[
        {
          img: require('@resources/img/pages/products/stainless-steel-water-bottles/icon-2.svg?url'),
          alt: t('Liquid icon'),
          text: t('Holds 24 oz of liquid'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-water-bottles/icon-1.svg?url'),
          alt: t('Stainless steel icon'),
          text: t('Sleek finish in gloss or matte that highly resists dents and scratches'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-water-bottles/icon-3.svg?url'),
        alt: t('Straw icon'),
          text: t('Sturdy twist-off lid with rigid carry loop'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-water-bottles/icon-4.svg?url'),
          alt: t('Dishwasher icon'),
          text: t('Durable and vibrant UV print won’t fade with use or wash. The stainless steel body is dishwasher safe.'),
        },
        {
          img: require('@resources/img/pages/products/stainless-steel-water-bottles/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/stainless-steel-water-bottles/gallery_0.jpg'),
      require('@resources/img/pages/products/stainless-steel-water-bottles/gallery_1.jpg'),
      require('@resources/img/pages/products/stainless-steel-water-bottles/gallery_2.jpg'),
      require('@resources/img/pages/products/stainless-steel-water-bottles/gallery_3.jpg'),
      require('@resources/img/pages/products/stainless-steel-water-bottles/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Stainless Steel <br/>Water Bottle <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Stainless Steel Water Bottle! At prices designed for reselling, Stainless Steel Water Bottle made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Stainless Steel Water Bottle Sizing')}
      text={t('Water bottle measurements may vary to a small degree. Holds 20 oz of liquid. The base color of the tumbler is cool white.')}
    >
      <img
        src={require('@resources/img/pages/products/stainless-steel-water-bottles/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <SectionBranding
      title={t('Brand your Stainless Steel Water Bottle')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Stainless Steel Water Bottle with these great products to create your own unique styles.')}
      productSlugs={['athletic-crop-top', 'yoga-shorts', 'headband']}
    />
  </div>
}
