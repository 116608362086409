import { ArrowDropDown as ArrowDropDownIcon, ExpandLessRounded as ExpandLessRoundedIcon, CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { Button, Fade, Grid, Grow, IconButton, Paper, Stack, Typography, styled } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ProductStore from '../../AppData/_stores/ProductStore';
import { TransitionGroup } from 'react-transition-group';
import ViewCategoryStore from '../../AppData/_stores/ViewCategoryStore';
import ErrorBoundary from '../../Common/_components/ErrorBoundary';
import ProductImage from './ProductImage';
import classNames from 'classnames';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import ProductCard from './ProductCard';
import ProductSidebar from './ProductSidebar';
import useRenderingTrace from '../../Common/_hooks/useRenderingTrace';
import ProductSelectorButton from './ProductSelectorButton';
import { EditState } from '../../UIData/_actions/DesignLabStoreActions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';



const ProductListWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 300ms, opacity 300ms',

  '&.hidden': {
    transform: 'translateX(100px)',
    opacity: 0,
  }
})

const ProductList = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
  marginBottom: '0',
  marginTop: '0',
  paddingBottom: '20px',
  paddingTop: '15px',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '200px'
  },
}));

const SubCategoryTitle = styled('div')({
  color: '#111',
  fontFamily: "'Bebas Neue', sans-serif",
  fontSize: '30px',
  margin: '20px 0 5px',
})

const ProductSelectorContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '64px',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  display: 'none',
  padding: '30px 30px 0',
  '&.opened': {
    display: 'flex'
  },
  ['@media (max-width: 1510px), (max-height: 1070px)']: {
    padding: '15px 15px 0'
  },
}));

const CategoryButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#d6d6d6',
  fontSize: '16px',
  lineHeight: 1.5,
  minWidth: 0,

  '&.selected': {
    backgroundColor: theme.palette.accent.main,
  }
}))

type Props = {};

export default function ProductSelector(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('design-lab');

  const listCategories = useAppSelector(state => state.get('appData').get('viewCategories'));
  const listProducts = useAppSelector(state => state.get('appData').get('products').filter(product => product.get('visible')), {
    equalityFn: (a, b) => a.equals(b)
  });
  const params = useParams<{ product?: string }>();
  const [searchParams] = useSearchParams();

  const [opened, setOpened] = useState(!params.product);
  const [hide, setHide] = useState(!!params.product);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setOpened(false);
        dispatch(EditState('lab'))
      }, 300);
    }
  }, [hide])

  useEffect(() => {
    if (opened) {
      dispatch(EditState('product-selector'))
      setTimeout(() => {
        setHide(false);
      }, 300);
    }
  }, [opened]);

  useEffect(() => {
    const categoryId = Number(searchParams.get('category'));
    if(categoryId && listCategories.has(String(categoryId))) {
      setSelectedCategory(categoryId)
    }
  }, [searchParams])

  const onSelectorHide = useCallback(() => setHide(true), [])

  const topLevelCategoryIds = useMemo(() => {
    let tmpList: number[] = [];
    listProducts.forEach(product => {
      product.get('viewCategories').forEach(viewCategory => {
        if (!tmpList.includes(viewCategory)) {
          tmpList.push(viewCategory);
        }
      });
    });
    return tmpList;
  }, [listProducts]);

  const listFilteredSubCategories = useMemo(() => {
    let tmpList = listCategories;

    //Filter by category
    if (selectedCategory) {
      tmpList = tmpList.filter(category => category.get('id_parent') === selectedCategory || category.get('id') === selectedCategory);
    } else {
      tmpList = tmpList.filter(category => topLevelCategoryIds.includes(category.get('id')));
    }

    return tmpList.sort((a, b) => {
      if (a.get('id') === b.get('id_parent')) {
        return 1;
      } else if (a.get('id_parent') === b.get('id')) {
        return -1;
      }

      return a.get('theorder') - b.get('theorder');
    });
  }, [listCategories, selectedCategory, topLevelCategoryIds])

  const listFilteredProducts = useMemo(() => {
    let tmpList = listProducts;

    //Filter by search
    if (search) {
      const listSearch = search.toLowerCase().replace('-', '').split(' ');
      tmpList = tmpList.filter(product => {
        const productName = product.get('name').toLowerCase().replace('-', '');
        let found = false;
        let hasSearchTerm = false;
        listSearch.forEach(searchTerm => {
          if (searchTerm === "" || searchTerm.length < 3) return;

          found = found || productName.indexOf(searchTerm) !== -1;
          hasSearchTerm = true;
        });

        return !hasSearchTerm || found;
      });
    }

    return tmpList.sort((a, b) => a.get('theorder') - b.get('theorder'));
  }, [listProducts, search]);

  const renderProductCard = useCallback((product: ProductStore) => {
    return <ProductCard
      key={product.get('id')}
      product={product}
      onSelectorHide={onSelectorHide}
    />
  }, [onSelectorHide]);

  const renderCategoryButton = useCallback((category: ViewCategoryStore) => {
    const isSelected = category.get('id') === selectedCategory;
    return <CategoryButton
      key={category.get('id')}
      variant="contained"
      color={isSelected ? 'accent' : 'gray'}
      onClick={() => setSelectedCategory(category.get('id'))}
      className={isSelected ? 'selected' : ''}
    >{category.get('name')}</CategoryButton>
  }, [selectedCategory])

  const renderSubcategory = useCallback((subCategory: ViewCategoryStore) => {
    const tmpListProducts = listFilteredProducts.filter(product => product.get('viewCategories').includes(subCategory.get('id')));
    const nbProducts = tmpListProducts.count();
    if (nbProducts === 0) return null;

    return <Grid item xs={12} key={subCategory.get('id')}>
      <SubCategoryTitle>{subCategory.get('name')}</SubCategoryTitle>

      <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
        {tmpListProducts.valueSeq().map(renderProductCard)}
      </Grid>
    </Grid>
  }, [listFilteredProducts, renderProductCard])

  const onOpenButtonClick = useCallback(() => {
    if (opened) {
      setHide(true);
    } else {
      setOpened(true);
    }
  }, [opened])

  const onStart = useCallback(() => {
    setHide(true)
  }, [])

  const onSearchSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return false
  }, [])

  const onChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }, [])

  const onAllClick = useCallback(() => {
    setSelectedCategory(null)
  }, [])

  return <div>
    <ProductSelectorButton
      opened={opened}
      onClick={onOpenButtonClick}
    />
    <ProductSelectorContainer className={opened ? 'opened' : ''}>
      <ProductSidebar
        onStart={onStart}
        hidden={hide}
      />
      <ProductListWrap
        className={hide ? 'hidden' : ''}
      >
        <form method="post" action="" onSubmit={onSearchSubmit}
          style={{
            background: '#fff',
            borderRadius: 30,
            padding: '0 20px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <input
            type="text"
            value={search}
            onChange={onChangeSearch}
            placeholder={t('Search product...')}
            style={{
              color: '#000',
              border: 0,
              padding: 0,
              height: '48px',
              margin: 0,
              boxShadow: 'none',
              width: '100%',
              outline: 0,
              fontFamily: 'Poppins, Roboto, sans-serif',
              fontSize: '18px',
            }}
          />
        </form>

        <ProductList
          container
          spacing={2}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{
                mb: 1,
                flexWrap: 'wrap',
              }}
            >
              <CategoryButton
                variant="contained"
                color={selectedCategory === null ? 'accent' : 'gray'}
                onClick={onAllClick}
                className={selectedCategory === null ? 'selected' : ''}
              >{t('All')}</CategoryButton>
              {listCategories.filter(category => category.get('id_parent') === null).valueSeq().map(renderCategoryButton)}
            </Stack>
          </Grid>
          {listFilteredSubCategories.valueSeq().map(renderSubcategory)}
        </ProductList>
      </ProductListWrap>
    </ProductSelectorContainer>
  </div>
}