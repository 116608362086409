import { useCallback, useContext, useEffect } from "react";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";
import { FindMaterial } from "../../Common/Utils/ThreeTools";

export default function StationeryCard(props: ProductModelProps) {
    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return {
            'portrait': new URL('@resources/3d-models/glb/stationery-card-portrait.glb', import.meta.url),
            'landscape': new URL('@resources/3d-models/glb/stationery-card-landscape.glb', import.meta.url),
        }[props.variant]
    }, [props.variant])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial("print", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}