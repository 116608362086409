import { useCallback, useContext, useEffect, useState } from "react";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools";
import { FrontSide, DoubleSide } from "three";

export default function BabyLeggings(props: ProductModelProps) {
    const option_size = props.options.get('size') ?? '6months'

    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_size ? {
            '6months': new URL('@resources/3d-models/glb/baby-leggings-6months.glb', import.meta.url),
            '1year': new URL('@resources/3d-models/glb/baby-leggings-1year.glb', import.meta.url),
            '2years': new URL('@resources/3d-models/glb/baby-leggings-2years.glb', import.meta.url),
            '3years': new URL('@resources/3d-models/glb/baby-leggings-3years.glb', import.meta.url),
        }[option_size] : undefined
    }, [option_size])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    useEffect(() => {
        // Hide Stretcher
        let obj = FindObject("stretcher", elements)
        if (!obj) return
        obj.visible = false

        // Enable see-through for inner material
        let mat = FindMaterial("out", elements)
        if(mat) {
            mat.side = DoubleSide
        }

        mat = FindMaterial("in", elements)
        if(mat) {
            mat.side = FrontSide
            mat.transparent = true
            mat.opacity = 0.8
        }
    }, [elements])
    
    // Apply lab texture
    useEffect(() => {
        let mat = FindMaterial('out', elements)
        if(mat) {
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements forceUpdate={option_size} elements={elements}/>
}