import { createAction } from "../../Common/_actions/Action";
import { UserGoalPayload } from "../_stores/UserGoalStore";

export enum UserGoalStoreActionType {
  EditUserGoal = 'USER_GOAL_EDIT_USER_GOAL',
  EditManyUserGoals = 'USER_GOAL_EDIT_MANY_USER_GOALS',
  RemoveUserGoal = 'USER_GOAL_REMOVE_USER_GOAL',
}

export const EditUserGoal = (userGoal: UserGoalPayload) => createAction(UserGoalStoreActionType.EditUserGoal, {
  userGoal: userGoal
});

export const EditManyUserGoals = (userGoals: Record<string, UserGoalPayload>) => createAction(UserGoalStoreActionType.EditManyUserGoals, {
  userGoals: userGoals
});

export const RemoveUserGoal = (id: number) => createAction(UserGoalStoreActionType.RemoveUserGoal, {
  id: id
});

export type UserGoalStoreAction =
    ReturnType<typeof EditUserGoal> |
    ReturnType<typeof EditManyUserGoals> |
    ReturnType<typeof RemoveUserGoal>