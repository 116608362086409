import { Box, Button, Card, CardContent, CardMedia, Collapse, Divider, IconButton, keyframes, LinearProgress, Paper, Snackbar, SnackbarContent, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConsumeToast } from '../../UIData/_actions/UIDataActions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import UserRewardCoupon from './UserRewardCoupon';
import UserRewardStore from '../../UserData/_stores/UserRewardStore';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UIToastStore from '../../UIData/_stores/UIToastStore';
import GoalToastStore from '../../UIData/_stores/GoalToastStore';
import RewardStore from '../../AppData/_stores/RewardStore';
import RewardBox from './RewardBox';

type Props = {
  open: boolean
  onClose: () => void
  toastKey: string
  goalData: GoalToastStore
}

export default function GoalToast(props: Props) {
  const [t] = useTranslation('account-v2');
  const theme = useTheme()
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const [oneRewardOpened, setOneRewardOpened] = useState(false);

  const goal = useAppSelector(state => state.get('appData').get('goals').get(String(props.goalData.get('id_goal'))));
  const rewards = useAppSelector(state => state.get('appData').get('rewards').filter(reward => goal?.get('listRewards').includes(reward.get('id'))), {
    equalityFn: (a, b) => a.equals(b),
  })

  const isComplete = useMemo(() => props.goalData.get('count') >= props.goalData.get('total'), [props.goalData]);

  const renderReward = useCallback((reward: RewardStore) => {
    return <RewardBox 
      key={reward.get('id')} 
      rewardId={reward.get('id')} 
      onOpened={() => setOneRewardOpened(true)}
    />
  }, []);

  if (!props.goalData || !goal) return null;

  return <Snackbar
    key={props.toastKey}
    open={props.open}
    anchorOrigin={{ vertical: breakpointSmDown ? 'bottom' : 'top', horizontal: 'right' }}
    autoHideDuration={!isComplete ? 5000 : undefined}
    onClose={props.onClose}
  >
    <Card sx={{ display: 'flex', p: 0, alignItems: 'flex-start', width: '500px' }} elevation={14}>
      <IconButton
        color="secondary"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseRoundedIcon fontSize="large" />
      </IconButton>
      <Stack sx={{flex: 1}}>
        <Stack
          direction="row"
          sx={{
            p: {
              xs: 1,
              sm: 2,
            }
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 120, objectFit: 'contain', height: 'auto', }}
            image={require('@resources/img/account/rewards/goals/' + goal.get('slug') + '.svg?url')}
            alt=""
          />
          <Stack sx={{ pl: 1, pr: 2, pt: 1, pb: 2, flex: 1 }} spacing={1} alignItems="stretch">
            {isComplete ? <>
              <Stack>
                <Typography variant="h4" sx={{ mr: 4 }} dangerouslySetInnerHTML={{ __html: goal.get('name') }} />
                <Typography variant="h4" sx={{ color: '#d4ba19', textTransform: 'uppercase' }}>{t('Goal completed!')}</Typography>
              </Stack>
              <Typography variant="body2">{t('Congratulations! You\'ve just unlocked a new reward!')}</Typography>
            </> : <>
              <Stack spacing={0.5}>
                <Typography variant="h4" sx={{ pr: 4 }} dangerouslySetInnerHTML={{ __html: goal.get('name') }} />
                <LinearProgress variant="determinate" value={50} color="success" sx={{ width: '80%', height: 5 }} />
                <Typography variant="h4" sx={{ color: 'success.main', textTransform: 'uppercase' }}>{t('{{count}} / {{total}} Completed!', { count: props.goalData.get('count'), total: props.goalData.get('total') })}</Typography>
              </Stack>
            </>}
          </Stack>
        </Stack>

        {isComplete ? <Stack
          spacing={1}
          sx={{
            background: '#ededed',
            p: {
              xs: 1,
              sm: 2,
            },
            alignItems: 'center'
          }}
        >
          {rewards.valueSeq().map(renderReward)}

          <Collapse in={oneRewardOpened}>
            <Typography variant="caption">{t('You can apply your rewards at checkout.')}</Typography>
          </Collapse>
        </Stack> : null}
      </Stack>
    </Card>
  </Snackbar>
}