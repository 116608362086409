import { Suspense, useCallback, useEffect, useMemo, useState } from 'react'

import { Alert, AlertTitle, Box, Button, Checkbox, Collapse, Fade, FormControlLabel, MenuItem, NoSsr, Stack, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Close as CloseIcon, ArrowUpward as ArrowUpwardIcon, ViewInAr as ViewInArIcon } from '@mui/icons-material'
import { FetchOneItem } from '../../UserData/_actions/UserDataActions'
import { type Texture, TextureLoader } from 'three'
import Immutable from 'immutable'
import ModelViewerCanvas from '../../ModelViewer/ModelViewerCanvas'
import Dinero from 'dinero.js'
import type ProductOptionStore from '../../AppData/_stores/ProductOptionStore'
import LabelV2 from '../../Common/Form/LabelV2'
import FabricViewer from '../../DesignLab/FabricLab/_components/FabricViewer'
import { GetProductLabData } from '../../UIData/_actions/DesignLabActions'
import useDocumentSize from '../../Common/_hooks/useDocumentSize'
import LabLoader from '../../DesignLab/_components/LabLoader'
import SelectV2 from '../../Common/Form/SelectV2'
import NumberFieldV2 from '../../Common/Form/NumberFieldV2'
import { type ProductItem } from '../../UserData/_stores/UserCartStore'
import { UpdateCartItem } from '../../UserData/_actions/UserCartActions'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { Link } from 'react-router-dom'
import AddToCartButton from '../../Cart/AddToCartButton'
import ProductBrandingOption from './ProductBrandingOption'
import { FetchBrandingOptions } from '../../UserData/_actions/BrandingOptionActions'
import ProductTextureContext from '../../ModelViewer/_contexts/ProductTextureContext'
import ModelViewer from '../../ModelViewer/ModelViewer'
import ErrorBoundary from '../../Common/_components/ErrorBoundary'

const MOBILE_BREAKPOINT = 760

const StyledDialogContent = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '25px',
  overflow: 'hidden',
  gap: '20px',

  ['@media (max-width: ' + MOBILE_BREAKPOINT + 'px)']: {
    flexDirection: 'column',
    overflow: 'auto',
    gap: '30px'
  },

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }
  }
}))

const PreviewListWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,

  ['@media (max-width: ' + MOBILE_BREAKPOINT + 'px)']: {
    order: 2
  }
})

const PreviewWrapper = styled('div')({
  backgroundColor: '#f3f3f3',
  overflow: 'auto',

  '&.is-3d': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }
  }
})

const ThumbnailList = styled('div')({
  display: 'flex',
  gap: 5,
  overflow: 'auto',
  paddingBottom: '5px',

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }
  }
})

const Thumbnail = styled('div')({
  border: '2px solid transparent',
  overflow: 'hidden',
  cursor: 'pointer',
  borderRadius: 15,
  position: 'relative',
  flexShrink: 0,

  '&.active': {
    borderColor: '#333'
  }
})

const ThumbnailImg = styled('img')({
  height: '90px',
  display: 'block'
})

const ThumbnailIconWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const FabricViewerWrapper = styled('div')({
  paddingBottom: '30px',
  display: 'flex'
})

const DetailsWrapper = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  padding: '0 10px 10px 10px',
  width: 290,
  flexShrink: 0,
  overflow: 'auto',
  justifyContent: 'safe center',

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }
  },

  ['@media (max-width: ' + MOBILE_BREAKPOINT + 'px)']: {
    width: '100%',
    order: 1
  }
}))

const Price = styled('div')(({ theme }) => ({
  fontSize: '22px',
  fontWeight: '600',
  color: theme.palette.accent.main
}))

const WholesaleNotice = styled('div')(({ theme }) => ({
  fontSize: '14px',
  marginTop: '5px',
  color: theme.palette.accent.main
}))

interface Props {
  productDesignId?: string
  item?: ProductItem
  onConfirm?: (listOptions: Immutable.Map<number, number>, listBrandingOptions: Immutable.Map<number, number>) => void
  selectedOptions?: Array<[number, number]>
  selectedBrandingOptions?: Array<[number, number]>
  opened: boolean
  onClose: () => void
}

export default function OrderDialogContent(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')

  const userCurrency = useAppSelector(state => state.get('userData').get('prefs').get('currency'))
  const productDesign = useAppSelector(state => props.productDesignId ? state.get('userData').get('productDesigns').get('data').get(props.productDesignId) : undefined)
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(productDesign?.get('id_product'))))
  const brandingOptions = useAppSelector(state => state.get('userData').get('brandingOptions'))

  const [selectedPreview, setSelectedPreview] = useState(0)
  const [texture, setTexture] = useState<HTMLImageElement | null>(null)
  const [quantity, setQuantity] = useState(1)
  const [listSelectedOptions, setListSelectedOptions] = useState(Immutable.Map<number, number>(props.selectedOptions ?? []))
  const [listSelectedBrandingOptions, setListSelectedBrandingOptions] = useState(Immutable.Map<number, number>(props.selectedBrandingOptions ?? []))
  const [addedToCart, setAddedToCart] = useState(false)
  const [error, setError] = useState(false)

  const documentSize = useDocumentSize()

  useEffect(() => {
    dispatch(FetchBrandingOptions())
  }, [])

  useEffect(() => {
    if (!props.productDesignId || !props.opened) return

    setQuantity(1)
    setAddedToCart(false)
    dispatch(FetchOneItem(props.productDesignId, 'productDesigns').set({
      onFailure: () => {
        setError(true)
      }
    }))
  }, [props.opened, props.productDesignId])

  useEffect(() => {
    if (props.opened && productData && !productData.get('labData')) {
      dispatch(GetProductLabData(productData.get('slug')))
    }
  }, [props.opened, productData])

  useEffect(() => {
    if (!props.selectedOptions) return
    setListSelectedOptions(Immutable.Map<number, number>(props.selectedOptions))
  }, [props.selectedOptions])

  useEffect(() => {
    if (!props.selectedBrandingOptions) return
    setListSelectedBrandingOptions(Immutable.Map<number, number>(props.selectedBrandingOptions))
  }, [props.selectedBrandingOptions])

  useEffect(() => {
    if (!productDesign) return

    let tmpSelectedOptions = listSelectedOptions
    let tmpSelectedBrandingOptions = listSelectedBrandingOptions

    if (props.selectedOptions) {
      tmpSelectedOptions = Immutable.Map<number, number>(props.selectedOptions)
    }

    if (props.selectedBrandingOptions) {
      tmpSelectedBrandingOptions = Immutable.Map<number, number>(props.selectedBrandingOptions)
    }

    // Remove options that don't exist anymore
    listSelectedOptions.forEach((optionId, optionTypeId) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))
      if (!optionType?.get('options').has(String(optionId))) {
        tmpSelectedOptions = tmpSelectedOptions.remove(optionTypeId)
      }
    })

    // Autoselect design default options
    productDesign.get('defaultOptions').forEach((optionId, optionTypeId) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))

      if (!optionType?.get('options').has(String(optionId))) return

      // Only select default if we don't already have a value
      if (!tmpSelectedOptions.has(optionTypeId)) {
        tmpSelectedOptions = tmpSelectedOptions.set(optionTypeId, optionId)
      }
    })

    // Autoselect default options
    productDesign.get('availableOptionTypes').forEach(optionType => {
      let defaultOption: ProductOptionStore | undefined;

      optionType.get('options').forEach(option => {
        if (option.get('is_default')) {
          defaultOption = option;
          return false;
        }
      })

      // Only select default if we don't already have a value
      if (defaultOption !== undefined && !tmpSelectedOptions.has(optionType.get('id'))) {
        tmpSelectedOptions = tmpSelectedOptions.set(optionType.get('id'), defaultOption.get('id'))
      }
    })

    // Autoselect hidden options
    productDesign.get('availableOptionTypes').forEach(optionType => {
      if (optionType.get('visible')) {
        return
      }

      // There should only be 1 option so select first one
      const firstOption = optionType.get('options').first()
      if (!firstOption) return

      tmpSelectedOptions = tmpSelectedOptions.set(optionType.get('id'), firstOption.get('id'))
    })

    // Autoselect default branding options
    productDesign.get('availableOptionTypes').forEach(optionType => {
      // TODO: Hardcoded until we have a proper way to identify branding option types
      if (optionType.get('slug') !== 'include-label') {
        return
      }

      // There should only be 1 option so select first one
      const firstOption = optionType.get('options').first()
      if (!firstOption) return

      brandingOptions
        .filter(brandingOption => brandingOption.get('id_type') === 1)
        .forEach(brandingOption => {
          if (brandingOption.get('is_default')) {
            tmpSelectedOptions = tmpSelectedOptions.set(optionType.get('id'), firstOption.get('id'))
            tmpSelectedBrandingOptions = tmpSelectedBrandingOptions.set(firstOption.get('id'), brandingOption.get('id'))
          }
        })
    })

    // Autoselect item options if provided
    if (props.item) {
      props.item.get('options').forEach(option => {
        tmpSelectedOptions = tmpSelectedOptions.set(option.get('type_id'), option.get('id'))

        const idBrandingOption = option.get('id_branding_option')
        if (idBrandingOption) {
          tmpSelectedBrandingOptions = tmpSelectedBrandingOptions.set(option.get('id'), idBrandingOption)
        }
      })
      setQuantity(props.item.get('quantity'))
    }

    setListSelectedOptions(tmpSelectedOptions)
    setListSelectedBrandingOptions(tmpSelectedBrandingOptions)

    // Autoselect first preview
    if (productDesign.get('textures').count() > 0) {
      setSelectedPreview(0)
    } else {
      setSelectedPreview(productDesign.get('previews').first()?.get('id') ?? 0)
    }
  }, [productDesign, brandingOptions, props.item])

  const subproduct = useMemo(() => {
    if (!productDesign || !productData || productData.get('subproducts').count() === 0) return null;

    //TODO: This should support having subproducts based on multiple option types though none exist at the time of writing
    let listOptionSlugs: string[] = [];
    productData.get('subproducts').forEach(subproduct => {
      //Get selected option that matches the subproduct's option type
      const optionType = productDesign.get('availableOptionTypes').get(String(subproduct.get('id_product_option_type')))
      const optionSlug = optionType?.get('options').get(String(listSelectedOptions.get(subproduct.get('id_product_option_type'))))?.get('slug')

      if (optionSlug) {
        listOptionSlugs.push(optionSlug);
      }
    });

    return listOptionSlugs.join('|');
  }, [productDesign, productData, listSelectedOptions])

  const textureUrl = useMemo(() => {
    if (!productDesign || !productData) return undefined;

    //For subproducts, we find the one that matches the selected subproduct
    if (productData.get('has_subproducts')) {
      let tmpTextureUrl = undefined;
      productDesign.get('textures').forEach(texture => {
        if (texture.get('slug') === subproduct + '-texture') {
          tmpTextureUrl = texture.get('url');
          return false;
        }
      })
      return tmpTextureUrl;
    }

    return productDesign?.get('textures').first()?.get('url')
  }, [productDesign, productData, subproduct])

  // For subproducts (with LEGACY model viewer)
  const textureUrls = useMemo(() => {
    let tmpTextureUrls = ''

    let i = 0
    productDesign?.get('textures').forEach(texture => {
      if (i > 0) {
        tmpTextureUrls += ';'
      }

      tmpTextureUrls += texture.get('slug')+'|'+texture.get('url')
      i++
    })

    return tmpTextureUrls
  }, [productDesign])

  useEffect(() => {
    if (!textureUrl) {
      setTexture(null)
      return
    }

    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      setTexture(image)
    }
    image.src = textureUrl
  }, [textureUrl])

  useEffect(() => {
    if (!productDesign) return

    let tmpSelectedOptions = listSelectedOptions
    listSelectedOptions.forEach((optionId, optionTypeId) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))
      if (!optionType) return

      const option = optionType.get('options').get(String(optionId))
      if (!option) return

      // Dependency check
      if (option.get('listDependencies').count() > 0) {
        let isValid = false
        option.get('listDependencies').forEach(dependency => {
          listSelectedOptions.forEach((optionId2, optionTypeId2) => {
            if (optionId2 === dependency) {
              isValid = true
            }
          })
        })

        if (!isValid) {
          // Find a similar option, or reset
          let newOption: ProductOptionStore | null = null
          for (const [, option2] of optionType.get('options')) {
            // Check for same slug
            if (option2.get('slug') !== option.get('slug')) {
              continue
            }

            // Verify dependency
            if (option2.get('listDependencies').count() > 0) {
              let isValid = false
              option2.get('listDependencies').forEach(dependency => {
                listSelectedOptions.forEach((optionId2, optionTypeId2) => {
                  if (optionId2 === dependency) {
                    isValid = true
                  }
                })
              })

              if (!isValid) continue
            }

            // Option is valid
            newOption = option2
          }

          if (newOption) {
            tmpSelectedOptions = tmpSelectedOptions.set(optionTypeId, newOption.get('id'))
          } else {
            tmpSelectedOptions = tmpSelectedOptions.remove(optionTypeId)
          }
        }
      }
    })

    setListSelectedOptions(tmpSelectedOptions)
  }, [listSelectedOptions])

  const modelOptions = useMemo(() => {
    let tmpModelOptions = Immutable.Map<string, string>()

    if (!productDesign) return tmpModelOptions

    //Add options
    listSelectedOptions.forEach((optionId, optionTypeId) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))
      const optionSlug = optionType?.get('options').get(String(optionId))?.get('slug')

      if (!optionType || !optionSlug) return

      tmpModelOptions = tmpModelOptions.set(optionType.get('slug'), optionSlug)
    })

    //Add details
    productDesign.get('details').forEach((valueSlug, detailSlug) => {
      tmpModelOptions = tmpModelOptions.set(detailSlug, valueSlug);
    })

    return tmpModelOptions
  }, [productDesign, listSelectedOptions, productData])

  // Calculate price
  const [price, discount] = useMemo(() => {
    // Default objects with the userCurrency to start
    let price = Dinero({
      amount: 0,
      currency: userCurrency
    })

    let discount = Dinero({
      amount: 0,
      currency: userCurrency
    })

    if (!productData || !productDesign) {
      return [price, discount]
    }

    // Base product price
    if (quantity >= 6) {
      price = productData.get('price_wholesale').toDinero()
    } else {
      price = productData.get('price_dropship').toDinero()
    }

    discount = productData.get('price_dropship').toDinero().subtract(productData.get('price_wholesale').toDinero())

    // Option prices
    listSelectedOptions.forEach((optionId, optionTypeId) => {
      const option = productDesign.get('availableOptionTypes').get(String(optionTypeId))?.get('options').get(String(optionId))

      if (!option) return

      if (quantity >= 6) {
        price = price.add(option.get('price_wholesale').toDinero())
      } else {
        price = price.add(option.get('price_dropship').toDinero())
      }

      discount = discount.add(option.get('price_dropship').toDinero().subtract(option.get('price_wholesale').toDinero()))
    })

    return [price, discount]
  }, [productData, productDesign, quantity, listSelectedOptions, userCurrency])

  const productionTime = useMemo(() => {
    if (!productData) return 0

    let tmpProductionTime = productData.get('production_time')

    // +3 days per 6 items
    tmpProductionTime += Math.max(0, Math.ceil((quantity - 6) / 6) * 3)

    // Max out at 30 days
    tmpProductionTime = Math.min(tmpProductionTime, 30)

    // Round to 1 digit
    tmpProductionTime = Math.round(tmpProductionTime * 10) / 10

    return tmpProductionTime
  }, [productData, quantity])

  const previewSize = useMemo(() => {
    // TODO: Can change this once we remove the scrollbar
    // documentSize - dialogPadding - contentPadding - detailPanel - gap, max 560
    let maxWidth = Math.min(documentSize.width - 64 - 50 - 290 - 30, 560)

    // documentSize - dialogPadding - contentPadding - dialogScrollbar, max 560
    if (documentSize.width <= MOBILE_BREAKPOINT) {
      maxWidth = Math.min(documentSize.width - 32 - 50 - 10, 560)
    }

    // documentSize - dialogPadding - contentPadding - thumbnailList - thumbnailScrollBar
    const maxHeight = documentSize.height - 64 - 50 - 105 - 10

    // Based on regular preview ratio
    const ratio = 500 / 560
    let width = maxWidth
    let height = width * ratio

    // Mobile is entirely dependent on width
    if (documentSize.width > MOBILE_BREAKPOINT && maxHeight / maxWidth < ratio) {
      height = maxHeight
      width = height / ratio
    }

    return {
      width,
      height
    }
  }, [documentSize])

  const isMissingOptions = useMemo(() => {
    if (!productDesign) {
      return false
    }

    let tmpIsMissingOptions = false
    productDesign.get('availableOptionTypes').forEach(optionType => {
      // Ignore bool options
      if (optionType.get('type') === 'bool') {
        return
      }

      // Skip hidden options
      if (!optionType.get('visible')) {
        return
      }

      if (!listSelectedOptions.has(optionType.get('id'))) {
        tmpIsMissingOptions = true
      }
    })

    return tmpIsMissingOptions
  }, [listSelectedOptions, productDesign])

  useEffect(() => {
    if (!productData || !productDesign || !props.opened) return

    if (productData.get('viewerVersion').get('version') === 1) {
      // @ts-expect-error Legacy THREE
      window.THREE.Cache.enabled = true
      setTimeout(() => {
        window.aow.initUI('#order-preview-container')
      })
    }
  }, [props.opened, productData, selectedPreview, texture])

  useEffect(() => {
    if (!productDesign || !productData || productData.get('viewerVersion').get('version') !== 1) {
      return
    }
    // This is to support legacy ModelViewer
    listSelectedOptions.forEach((optionId, optionTypeId) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))
      if (!optionType) return

      $('#productInfo-productOption-' + optionType.get('slug')).trigger('change')
    })
  }, [listSelectedOptions, props.opened])

  const fabricVariant = useMemo(() => {
    if (!productDesign || productDesign.get('id_product') !== 50) return null

    let fabric: string | undefined
    let size: string | undefined

    for (const [, optionType] of productDesign.get('availableOptionTypes')) {
      if (optionType.get('slug') === 'fabric') {
        fabric = optionType.get('options').get(String(listSelectedOptions.get(optionType.get('id'))))?.get('slug')
      } else if (optionType.get('slug') === 'fabric-size') {
        size = optionType.get('options').get(String(listSelectedOptions.get(optionType.get('id'))))?.get('slug')
      }
    }

    return fabric && size ? fabric + '|' + size : null
  }, [productDesign, listSelectedOptions])

  const onConfirm = useCallback(() => {
    props.onConfirm && props.onConfirm(listSelectedOptions, listSelectedBrandingOptions)
  }, [listSelectedOptions, listSelectedBrandingOptions, props.onConfirm])

  const optionString = useMemo(() => {
    if (!productDesign) return ''

    let optionString = ''
    let i = 0;
    listSelectedOptions.entrySeq().forEach(([optionTypeId, optionId]) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))
      const option = optionType?.get('options').get(String(optionId))

      if (!optionType || !option || ['include-label', 'printing-sides'].includes(optionType.get('slug'))) return ''

      optionString += (i > 0 ? ', ' : '') + option.get('name')
      i++;
    })
    return optionString
  }, [listSelectedOptions, productDesign])

  const onAddToCart = useCallback((cartId?: number, newCartName?: string, onSuccess?: () => void) => {
    if (!productDesign) return

    const fd = new FormData()

    if (cartId != null) {
      fd.append('cart_id', String(cartId))
    } else if (newCartName) {
      fd.append('cart_name', newCartName)
    }

    fd.append('quantity', String(quantity))
    fd.append('type', 'product')
    fd.append('product_id', String(productDesign.get('id_product')))
    fd.append('item_id', String(productDesign.get('id_product_design')))

    const option_ids:number[] = [];
    listSelectedOptions.forEach((optionId, optionTypeId) => {
      const optionType = productDesign.get('availableOptionTypes').get(String(optionTypeId))
      if (!optionType) return

      fd.append('options[' + optionType.get('slug') + ']', String(optionId))
      option_ids.push(optionId);
    })

    listSelectedBrandingOptions.forEach((brandingOptionId, optionId) => {
      fd.append('brandingOptions[' + optionId + ']', String(brandingOptionId))
    })

    dispatch(UpdateCartItem(fd).set({
      onSuccess: () => {
        setAddedToCart(true)
        onSuccess && onSuccess()
        
        const id = 'product-'+[productDesign.get('id_product'), ...option_ids].join('-');
        const content_ids = [id];
        const contents: { id: string, quantity: number }[] = [{
          id: id,
          quantity: quantity,
        }]
        const totalPrice = price.multiply(quantity);

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        window.fbq && window.fbq('track', 'AddToCart', {
          content_type: 'product',
          content_ids: content_ids,
          contents: contents,
          currency: totalPrice.getCurrency(),
          value: totalPrice.toUnit(),
        })
      }
    }))
  }, [quantity, productDesign, listSelectedOptions, listSelectedBrandingOptions, price])

  const renderTexture = useCallback(() => {
    return texture !== null ? texture : undefined
  }, [texture])

  const variant = useMemo(() => {
    return productData?.get('variants').get(String(productDesign?.get('id_product_variant')))?.get('slug') ?? 'default'
  }, [productData, productDesign?.get('id_product_variant')])

  const labData = useMemo(() => {
    const fullLabData = productData?.get('labData');

    if (!fullLabData) return undefined;

    if (fullLabData.has_subproducts) {
      return subproduct ? fullLabData.subproducts[subproduct]?.originalData : undefined;
    }

    return fullLabData.originalData;
  }, [productData, subproduct])

  return <StyledDialogContent>
    {(!productDesign || !productData) ? <>
      <Box
        component="div"
        sx={{
          py: 4,
          px: 8,
          minHeight: '200px',
          minWidth: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {error ? <Stack spacing={2} alignItems="center">
          <Alert
            severity="error"
          >{t('An error occurred while loading the product.')}</Alert>
          <Button
            variant="outlined"
            color="secondary"
            onClick={props.onClose}
          >{t('Close')}</Button>
        </Stack> : <LabLoader />}
      </Box>
    </> : <>
      <PreviewListWrapper style={{
        width: previewSize.width
      }}>
        <div style={{
          overflow: 'hidden',
          borderRadius: 20,
          height: previewSize.height,
          position: 'relative',
          backgroundColor: '#ededed'
        }}>
          {productData.get('viewerVersion').get('version') === 1 || texture ? <Fade
            in={selectedPreview === 0}
          >
            <PreviewWrapper className={productDesign.get('id_product') !== 50 ? 'is-3d' : ''} style={{ width: previewSize.width, height: previewSize.height }}>
              {
                productDesign.get('id_product') === 50 ? <FabricViewerWrapper style={{ width: previewSize.width - 10 }}>
                  {fabricVariant && labData ? <FabricViewer
                    labData={labData}
                    variant={fabricVariant}
                    textureUrl={textureUrl}
                    inLab={false}
                    options={modelOptions}
                  /> : null}
                </FabricViewerWrapper> : (productData.get('viewerVersion').get('version') === 1 ? <div id="order-preview-container" style={{ width: '100%', height: '100%' }}>
                  {productData.get('has_subproducts') ? <div
                    className="image-gallery-3dmodelcontainer"
                    data-product-variant={variant !== 'default' ? variant : undefined}
                    data-product-slug={productData.get('slug')}
                    data-product-id={productData.get('id')}
                    data-textures={textureUrls}
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden'
                    }}
                  ></div> : (textureUrl ? <div
                    className="image-gallery-3dmodel"
                    data-product-variant={variant !== 'default' ? variant : undefined}
                    data-product-slug={productData.get('slug')}
                    data-product-id={productData.get('id')}
                    data-texture={textureUrl}
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      overflow: 'hidden'
                    }}
                  ></div> : null)}

                  {productDesign.get('availableOptionTypes').valueSeq().map(optionType => {
                    // Default to first option if none are selected so we don't break the models
                    const option = listSelectedOptions.get(optionType.get('id')) !== undefined ? optionType.get('options').get(String(listSelectedOptions.get(optionType.get('id')))) : optionType.get('options').first()
                    if (!option) return null

                    return <select
                      key={optionType.get('id')}
                      id={'productInfo-productOption-' + optionType.get('slug')}
                      value={option.get('id')}
                      className="browser-default"
                      style={{ display: 'none' }}
                      onChange={() => { }}
                    >
                      <option value={option.get('id')} data-slug={option.get('slug')}></option>
                    </select>
                  })}
                </div> : (productData.get('viewerVersion').get('version') === 2 && texture ? <Suspense fallback={<LabLoader />}>
                  <NoSsr>
                    <ErrorBoundary fallback={<Alert
                      severity="error"
                    >{t('An error occurred while loading the 3D preview.')}</Alert>}>
                      {labData ? <ModelViewer
                        renderTexture={renderTexture}
                        viewerId="order-dialog"
                        labData={labData}
                        productId={productDesign.get('id_product')}
                        options={modelOptions}
                        variant={variant}
                      /> : null}
                    </ErrorBoundary>
                  </NoSsr>
                </Suspense>
                  : <></>)
                )
              }

            </PreviewWrapper>
          </Fade> : null}

          {productDesign.get('previews').valueSeq().map(preview => {
            return <Fade
              key={preview.get('id')}
              in={selectedPreview === preview.get('id')}
            >
              <img
                src={preview.get('url')}
                width={previewSize.width}
                height={previewSize.height}
                style={{
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              />
            </Fade>
          })}
        </div>
        <ThumbnailList>
          {textureUrl ? <Thumbnail
            className={selectedPreview === 0 ? 'active' : ''}
            onClick={() => setSelectedPreview(0)}
          >
            <ThumbnailImg src={productDesign.get('preview')} />
            <ThumbnailIconWrapper>
              <ViewInArIcon style={{
                width: '70%',
                height: '70%',
                color: '#fff'
              }} />
            </ThumbnailIconWrapper>
          </Thumbnail> : null}
          {productDesign.get('previews').sort((a, b) => a.get('theorder') - b.get('theorder')).valueSeq().map(preview => {
            return <Thumbnail
              key={preview.get('id')}
              onClick={() => setSelectedPreview(preview.get('id'))}
              className={selectedPreview === preview.get('id') ? 'active' : ''}
            >
              <ThumbnailImg src={preview.get('url')} />
            </Thumbnail>
          })}
        </ThumbnailList>
      </PreviewListWrapper>

      <DetailsWrapper>
        <Typography
          variant="h2"
          style={{
            fontSize: '36px'
          }}
        >{productDesign.get('name')}</Typography>
        <Typography
          variant="body1"
          style={{
            marginBottom: 20
          }}
        >{productData.get('name')}</Typography>

        <Collapse in={addedToCart}>
          <Stack spacing={2}>
            <Alert
              severity="success"
            ><AlertTitle>{t('Added to cart')}</AlertTitle>
              {String(quantity) + 'x ' + productData.get('name')}<br />
              {(optionString !== '' ? '(' + optionString + ')' : '')}
            </Alert>
            <Stack
              direction="row"
              spacing={2}
            >
              <Button
                variant="contained"
                color="accent"
                component={Link}
                to={tR('/cart')}
                reloadDocument
              >{t('Go to Cart')}</Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setAddedToCart(false)}
              >{t('Order more')}</Button>
            </Stack>
          </Stack>
        </Collapse>
        <Collapse in={!addedToCart}>
          <Stack spacing={2}>
            {productDesign.get('availableOptionTypes').sort((a, b) => a.get('theorder') - b.get('theorder')).valueSeq().map(optionType => {
              // Skip hidden options
              if (!optionType.get('visible')) {
                return null
              }

              let value: number | undefined = listSelectedOptions.get(optionType.get('id'))

              // Default to empty if it's invalid
              if (!optionType.get('options').has(String(value))) {
                value = undefined
              }

              if (optionType.get('type') === 'bool') {
                const singleOption = optionType.get('options').first()
                if (!singleOption) return null

                const price = (quantity >= 6 ? singleOption.get('price_wholesale') : singleOption.get('price_dropship')).toDinero()

                // TODO: Hardcoded until we have a proper way to identify branding option types
                if (optionType.get('slug') === 'include-label') {
                  const selectedId = listSelectedBrandingOptions.get(singleOption.get('id'))
                  return <div key={optionType.get('id')}>
                    <ProductBrandingOption
                      typeId={1}
                      value={selectedId}
                      onChange={(idBrandingOption) => {
                        setListSelectedOptions(value => idBrandingOption !== undefined
                          ? value.set(optionType.get('id'), singleOption.get('id'))
                          : value.remove(optionType.get('id'))
                        )
                        setListSelectedBrandingOptions(value => idBrandingOption !== undefined
                          ? value.set(singleOption.get('id'), idBrandingOption)
                          : value.remove(singleOption.get('id'))
                        )
                      }}
                    />
                  </div>
                } else {
                  return <div key={optionType.get('id')}>
                    <FormControlLabel
                      key={optionType.get('id')}
                      control={<Checkbox
                        checked={listSelectedOptions.get(optionType.get('id')) === singleOption.get('id')}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setListSelectedOptions(listSelectedOptions.set(optionType.get('id'), singleOption.get('id')))
                          } else {
                            setListSelectedOptions(listSelectedOptions.remove(optionType.get('id')))
                          }
                        }}
                      />}
                      label={optionType.get('name') + (!price.isZero() ? ' (' + price.toFormat() + ')' : '')}
                    />
                  </div>
                }
              } else {
                return <SelectV2
                  key={optionType.get('id')}
                  label={optionType.get('name')}
                  value={value ?? ''}
                  onChange={(e) => {
                    setListSelectedOptions(listSelectedOptions.set(optionType.get('id'), Number(e.target.value)))
                  }}
                >
                  {optionType.get('options').sort((a, b) => a.get('theorder') - b.get('theorder')).valueSeq().map(option => {
                    // Dependency check
                    if (option.get('listDependencies').count() > 0) {
                      let isValid = false
                      option.get('listDependencies').forEach(dependency => {
                        listSelectedOptions.forEach((optionId, optionTypeId) => {
                          if (optionId === dependency) {
                            isValid = true
                          }
                        })
                      })

                      if (!isValid) return null
                    }

                    const price = (quantity >= 6 ? option.get('price_wholesale') : option.get('price_dropship')).toDinero()

                    return <MenuItem
                      key={option.get('id')}
                      value={option.get('id')}
                    >{option.get('name') + (!price.isZero() ? ' (' + price.toFormat() + ')' : '')}</MenuItem>
                  })}
                </SelectV2>
              }
            })}

            <div style={{
              position: 'relative'
            }}>
              <div style={{
                filter: isMissingOptions ? 'blur(5px)' : ''
              }}>
                {props.item ? <Stack spacing={2} alignItems="flex-start">
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <div style={{
                      position: 'relative'
                    }}>
                      {quantity >= 6 && !discount.isZero() && discount.isPositive() ? <div style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        textDecoration: 'line-through',
                        color: '#999',
                        position: 'absolute',
                        top: '-12px'
                      }}>{price.add(discount).toFormat()} </div> : null}
                      <Price>{price.toFormat()} </Price>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    color="accent"
                    onClick={onConfirm}
                  >{t('Confirm')}</Button>
                </Stack> : <>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <LabelV2
                      htmlFor="order-quantity"
                    >{t('Quantity')}</LabelV2>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                      <NumberFieldV2
                        id="order-quantity"
                        value={quantity}
                        onChange={setQuantity}
                        min={1}
                        max={99999}
                      />
                      <div style={{
                        margin: '0 5px'
                      }}><CloseIcon fontSize="small" style={{ display: 'block' }} /></div>
                      <div style={{
                        position: 'relative'
                      }}>
                        {quantity >= 6 && !discount.isZero() && discount.isPositive() ? <div style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          textDecoration: 'line-through',
                          color: '#999',
                          position: 'absolute',
                          top: '-12px'
                        }}>{price.add(discount).toFormat()} </div> : null}
                        <Price>{price.toFormat()} </Price>
                      </div>
                    </div>
                  </div>

                  {quantity < 6 && !discount.isZero() && discount.isPositive()
                    ? <WholesaleNotice>{t('Add {{count}} more to get {{discount}} off each!', { count: (6 - quantity), discount: discount.toFormat() })}</WholesaleNotice>
                    : null
                  }

                  <div style={{
                    fontSize: '14px',
                    marginTop: '10px',
                    marginBottom: '20px'
                  }}>{t('Production Time: ')}<strong>~{t('{{productionTime}} business days', { productionTime })}</strong></div>

                  <AddToCartButton onAdd={onAddToCart} />
                </>}
              </div>

              {isMissingOptions ? <div style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
                fontWeight: 500,
                gap: '10px'
              }}>{t('Select your options')} <ArrowUpwardIcon fontSize="small" /></div> : null}
            </div>
          </Stack>
        </Collapse>
      </DetailsWrapper>
    </>}
  </StyledDialogContent>
}
