import { useCallback, useContext, useEffect } from "react";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools";
import { FrontSide, DoubleSide } from "three";

export default function YouthLeggings(props: ProductModelProps) {
    const option_size = props.options.get('size') ?? '4to5years'

    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_size ? {
            '4to5years': new URL('@resources/3d-models/glb/youth-leggings-4to5years.glb', import.meta.url),
            '6to7years': new URL('@resources/3d-models/glb/youth-leggings-6to7years.glb', import.meta.url),
            '8to9years': new URL('@resources/3d-models/glb/youth-leggings-8to9years.glb', import.meta.url),
            '10to12years': new URL('@resources/3d-models/glb/youth-leggings-10to12years.glb', import.meta.url),
        }[option_size] : undefined
    }, [option_size])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    useEffect(() => {
        // Hide Stretcher
        let obj = FindObject("stretcher", elements)
        if (!obj) return
        obj.visible = false

        // Enable see-through for inner material
        let mat = FindMaterial("out", elements)
        if(mat) {
            mat.side = DoubleSide
        }

        mat = FindMaterial("in", elements)
        if(mat) {
            mat.side = FrontSide
            mat.transparent = true
            mat.opacity = 0.8
        }
    }, [elements])
    
    // Apply lab texture
    useEffect(() => {
        let mat = FindMaterial('out', elements)
        if(mat) {
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements forceUpdate={option_size} elements={elements}/>
}