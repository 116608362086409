import { useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { DoubleSide } from "three"

export default function TeaTowel(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/tea-towel.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Apply lab texture
    useEffect(() => {
        if (!elements) return

        let design_mat = FindMaterial('towel_design', elements)
        if(design_mat){
            design_mat.map = productTexture
            design_mat.map.flipY = false
            design_mat.map.needsUpdate = true
            design_mat.side = DoubleSide
            design_mat.aoMapIntensity = 0.5
        }
    
        let fabric_mat = FindMaterial('towel_fabric', elements)
        if(fabric_mat){
            fabric_mat.transparent = true
            fabric_mat.opacity = 0.5
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}