import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import GoalStore from "../../AppData/_stores/GoalStore";
import { DialogContent, Grid, LinearProgress, Stack, styled, Tab, Tooltip } from "@mui/material";
import Goal from "./Goal";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const StyledTabList = styled(TabList)(({ theme }) => ({

}))

const StyledTab = styled(Tab)(({ theme }) => ({
  borderRadius: '50%',
  margin: theme.spacing(0, 2, 1),
  width: '60px',
  height: '60px',
  minWidth: 0,
  transition: 'background-color 250ms',

  'img': {
    opacity: 0.5,
  },

  '&:hover': {
    backgroundColor: '#ffebf7',
  },

  '&.Mui-selected': {
    backgroundColor: '#ffebf7',

    'img': {
      opacity: 1,
    }
  }
}))

const StyledTabPanel = styled(TabPanel)({
  display: 'flex',
  minHeight: 0,
  padding: 0,
  flex: 1,

  '&[hidden]': {
    display: 'none',
  }
})

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }
  }
}))

export default function GoalList() {
  const listGoals = useAppSelector(state => state.get('appData').get('goals'))
  const listGoalCategories = useAppSelector(state => state.get('appData').get('goalCategories'))
  const userGoals = useAppSelector(state => state.get('userData').get('goals'))
  const [selectedCategory, setSelectedCategory] = useState('1')

  const onChangeTab = useCallback((event: SyntheticEvent, value: string) => {
    setSelectedCategory(value)
  }, [])

  const renderGoal = useCallback((goal: GoalStore) => (
    <Goal
      key={goal.get('id')}
      id={goal.get('id')}
    />
  ), []);

  useEffect(() => {
    if (!selectedCategory) setSelectedCategory(String(listGoalCategories.first()?.get('id')))
  }, [listGoalCategories])

  return <TabContext value={String(selectedCategory)}>
    <Stack direction="row" sx={{ flex: 1 }}>
      <StyledTabList
        onChange={onChangeTab}
        orientation="vertical"
        sx={{
          flexShrink: 0,
        }}
        TabIndicatorProps={{ 
          sx: {
            display: 'none'
          }
        }}
      >
        {listGoalCategories.valueSeq().map(goalCategory => {
          return <StyledTab
            key={goalCategory.get('id')}
            disableRipple
            label={<Tooltip title={goalCategory.get('name')} placement="right">
              <img
                src={require('@resources/img/account/rewards/goals/categories/' + goalCategory.get('slug') + '.svg?url')}
                style={{
                  width: '36px',
                }}
              />
            </Tooltip>}
            value={String(goalCategory.get('id'))}
          />
        })}
      </StyledTabList>
      {listGoalCategories.valueSeq().map(goalCategory => {
        const [count,total] = listGoals
          .filter(goal => goal.get('id_goal_category') === goalCategory.get('id'))
          .reduce((reduction, goal) => [reduction[0] + (userGoals.get(String(goal.get('id')))?.get('progress') ?? 0), reduction[1] + goal.get('total')], [0,0])

        return <StyledTabPanel key={goalCategory.get('id')} value={String(goalCategory.get('id'))}>
          <StyledDialogContent style={{ paddingTop: '16px', paddingLeft: '16px', paddingRight: '32px', }}>
            <Stack spacing={2}>
              <Stack>
                <Stack direction="row" justifyContent="space-between" spacing={0.5} sx={{fontWeight: 500}}>
                  <span>{goalCategory.get('name')}</span>
                  <span>{Math.round((count / total) * 100)}%</span>
                </Stack>
                <LinearProgress variant="determinate" color="accent" value={(count / total) * 100} sx={{ height: 5 }} />
              </Stack>
              <div>
                <Grid container spacing={3}>
                  {listGoals.filter(goal => goal.get('id_goal_category') === goalCategory.get('id')).valueSeq().map(renderGoal)}
                </Grid>
              </div>
            </Stack>
          </StyledDialogContent>
        </StyledTabPanel>
      })}
    </Stack>
  </TabContext>
}