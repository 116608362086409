import { useContext, useEffect } from "react";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";
import { FindMaterial } from "../../Common/Utils/ThreeTools";
import { FrontSide, DoubleSide } from "three";

export default function Peignoir(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/peignoir.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Handle belt
    let option_trimBelt = props.options.get("trim-belt") ?? "black"
    useEffect(() => {
        let mat = FindMaterial("belt", elements)
        if(mat) {
            switch (option_trimBelt) {
                case 'black':
                    mat.color.setHex(0x101010)
                    break;
                case 'cream':
                    mat.color.setHex(0xf5eccd)
                    break;
                case 'dark-gray':
                    mat.color.setHex(0x5d5d5d)
                    break;
            }
        }
    }, [option_trimBelt])

    useEffect(() => {
        // Enable see-through for inner material
        let mat = FindMaterial("out", elements)
        if(mat) {
            mat.side = DoubleSide
        }

        mat = FindMaterial("in", elements)
        if(mat) {
            mat.side = FrontSide
            mat.transparent = true
            mat.opacity = 0.8
        }
    }, [elements])
    
    // Apply lab texture
    useEffect(() => {
        let mat = FindMaterial('out', elements)
        if(mat) {
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}