import { useCallback, useContext, useEffect } from "react"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools"

export default function MarketTote(props: ProductModelProps) {
    const option_style = props.options.get('style') || "market";
    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_style ? {
            'basic': new URL('@resources/3d-models/glb/market-tote-basic.glb', import.meta.url),
            'market': new URL('@resources/3d-models/glb/market-tote.glb', import.meta.url),
        }[option_style] : undefined
    }, [option_style])
    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Handle fabric
    const option_fabric = props.options.get('fabric');
    useEffect(() => {
        let obj = FindObject('design_'+option_fabric, elements)
        if(obj) obj.visible = true
        
        obj = FindObject('design_'+(option_fabric == "canvas" ? "cotton-canvas" : "canvas"), elements)
        if(obj) obj.visible = false

        if(option_style == "basic") {
            obj = FindObject('inside_'+option_fabric, elements)
            if(obj) obj.visible = true
            
            obj = FindObject('inside_'+(option_fabric == "canvas" ? "cotton-canvas" : "canvas"), elements)
            if(obj) obj.visible = false
        }
    }, [elements, option_style, option_fabric])

    // Handle Strap
    const option_strapColor = props.options.get('strap-color')
    useEffect(() => {
        let mat_strap = FindMaterial("Strap", elements)
        if(!mat_strap) return
        switch (option_strapColor) {
            case 'cotton-black':
                mat_strap.color.setHex(0x2b2f2f)
                break;
            case 'cotton-natural':
                mat_strap.color.setHex(0xd9d4c8)
                break;
        }
    }, [option_strapColor, option_style, elements])

    // Apply lab texture
    useEffect(() => {
        if(!elements) return

        let obj = FindObject('design_'+option_fabric, elements)
        if(obj) {
            obj.material.map = productTexture
            obj.material.map.needsUpdate = true
            obj.material.map.flipY = false
        }
    }, [productTexture, elements, option_style, option_fabric])
    
    return <ModelElements elements={elements}/>
}