import { createAction } from '../../Common/_actions/Action';
import { GoalToastPayload } from '../_stores/GoalToastStore';

export type SignupStatus = "signup" | "success" | "mail" | "confirm" | "validation" | "exists";
export type CartStatus = "cart" | "checkout" | "paid";
export type PasswordStatus = "forgot" | "forgot-sent" | "reset" | "reset-done" | "invalid-token";

export enum UIDataActionType {
	Toast = "UI_TOAST",
	ConsumeToast = "UI_TOAST_CONSUME",
	EditCountries = "UI_EDIT_COUNTRIES",
	EditLoader = "UI_EDIT_LOADER",
	RegisterScrollBlock = "UI_REGISTER_SCROLL_BLOCK",
	UnregisterScrollBlock = "UI_UNREGISTER_SCROLL_BLOCK",
	StartInterval = "UI_INTERVAL_START",
	StopInterval = "UI_INTERVAL_STOP",
	ApiError = "UI_API_ERROR",
	ResetApiErrors = "UI_RESET_API_ERRORS",
	EditValidData = "UI_EDIT_VALID_DATA",
	EditCreateUrl = "UI_EDIT_CREATE_URL",
}

export const Toast = (message: string, goalData?: GoalToastPayload) => createAction(UIDataActionType.Toast, {
	message: message,
	goalData: goalData
});

export const ConsumeToast = () => createAction(UIDataActionType.ConsumeToast);

export const EditLoader = (loader: string, value: boolean) => createAction(UIDataActionType.EditLoader, {
	loader: loader,
	value: value
});

export const StartInterval = (name: string, call: Function, milliseconds: number) => createAction(UIDataActionType.StartInterval, {
	name: name,
	call: call,
	milliseconds: milliseconds,
});

export const StopInterval = (name: string) => createAction(UIDataActionType.StopInterval, {
	name: name,
});

export const RegisterScrollBlock = (blockerID: string) => createAction(UIDataActionType.RegisterScrollBlock, {
	blockerID: blockerID
});

export const UnregisterScrollBlock = (blockerID: string) => createAction(UIDataActionType.UnregisterScrollBlock, {
	blockerID: blockerID
});

export const ApiError = (key: string, error: Record<string, any>) =>
	createAction(UIDataActionType.ApiError, {
		key: key,
		error: error,
	});

export const ResetApiErrors = () =>
	createAction(UIDataActionType.ResetApiErrors, {});

export const EditValidData = (dataKey: string, value: boolean) => createAction(UIDataActionType.EditValidData, {
	dataKey: dataKey,
	value: value
});

export const EditCreateUrl = (createUrl: string) => createAction(UIDataActionType.EditCreateUrl, {
	createUrl: createUrl,
});

export type UIDataAction =
	ReturnType<typeof Toast> |
	ReturnType<typeof ConsumeToast> |
	ReturnType<typeof EditLoader> |
	ReturnType<typeof RegisterScrollBlock> |
	ReturnType<typeof UnregisterScrollBlock> |
	ReturnType<typeof StartInterval> |
	ReturnType<typeof StopInterval> |
	ReturnType<typeof ApiError> |
	ReturnType<typeof ResetApiErrors> |
	ReturnType<typeof EditValidData> |
	ReturnType<typeof EditCreateUrl>
