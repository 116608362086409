import { Box, Button, Collapse, keyframes, styled, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import UserRewardCoupon from "./UserRewardCoupon";

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
`;

const explode = keyframes`
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(2); opacity: 0; }
`;

const appear = keyframes`
  0% { transform: scale(0); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
`;

const RewardButton = styled(Button)(({ theme }) => ({
  background: '#fbf374',
  border: '2px solid #d4ba19',
  boxShadow: '4px 4px 0 0 #d4ba19',
  color: '#ab9300',
  transition: 'background 200ms, box-shadow 200ms, transform 200ms',
  position: 'relative',
  zIndex: 2,

  '&:hover': {
    background: '#fbf374',
    boxShadow: '7px 7px 0 0 #d4ba19',
    transform: 'translate(-3px, -3px)',
  },
  '&:focus': {
    background: '#fbf374',
    boxShadow: '0px 0px 0 0 #d4ba19',
    transform: 'translate(4px, 4px)',
  },
  '&:before': {
    content: '""',
    background: 'url(' + require('@resources/img/account/rewards/decoration-sparkle-1.svg?url') + ')',
    width: '20px',
    height: '20px',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom: '-12px',
    left: '-12px',
  },
  '&:after': {
    content: '""',
    background: 'url(' + require('@resources/img/account/rewards/decoration-sparkle-2.svg?url') + ')',
    width: '28px',
    height: '26px',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: '-16px',
    right: '-16px',
  }
}))

const RewardWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  animation: `${appear} 0.25s forwards`,
  width: '100%',
}))

interface Props {
  rewardId: number
  onOpened?: () => void
}

export default function RewardBox(props: Props) {
  const [t] = useTranslation('account-v2');
  const reward = useAppSelector(state => state.get('appData').get('rewards').get(String(props.rewardId)));

  const [giftBoxExplosion, setGiftBoxExplosion] = useState(false);
  const [giftBoxExploded, setGiftBoxExploded] = useState(false);
  const [animSx, setAnimSx] = useState({});

  const onGiftBoxOpen = useCallback(() => {
    setAnimSx({
      animation: `${shake} 0.5s infinite`
    });
    setTimeout(() => {
      setGiftBoxExplosion(true);
      setAnimSx({
        animation: `${explode} 0.5s forwards`
      });
      setTimeout(() => {
        setGiftBoxExploded(true);
        props.onOpened && props.onOpened();
      }, 500)
    }, 750)
  }, []);

  if (!reward) return null;

  return <Box
      component="div"
      sx={{
        position: 'relative',
        minHeight: '100px',
        width: '100%',
        maxWidth: '350px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {!giftBoxExploded ? <RewardButton
        variant="contained"
        color="white"
        size="medium"
        onClick={onGiftBoxOpen}
        startIcon={<img
          src={require('@resources/img/account/rewards/icon-reward-nobg.svg?url')}
          style={{
            height: '24px',
            marginTop: '-4px',
            marginLeft: '-4px',
          }}
        />}
        endIcon={<img
          src={require('@resources/img/account/rewards/decoration-reflection-1.svg?url')}
          style={{
            height: '20px',
            marginBottom: '-7px',
            marginLeft: '-28px',
          }}
        />}
        sx={animSx}
      >{t('Open my reward')}</RewardButton> : null}

      {giftBoxExplosion ? <RewardWrapper>
        <UserRewardCoupon
          reward={reward}
          small
          fullWidth
          bgColor="#ededed"
        />
      </RewardWrapper> : null}
    </Box>
}