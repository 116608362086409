import Immutable from "immutable";

export interface UserGoalPayload {
	id_goal: number;
	progress: number;
}

export interface IUserGoalStore {
	id_goal: number;
	progress: number;
}

const defaultUserGoalStore = Immutable.Record<IUserGoalStore>({
	id_goal: 0,
	progress: 0,
});

export default class UserGoalStore extends defaultUserGoalStore implements IUserGoalStore {}
