import { useContext, useEffect } from "react"
import { Color } from "three"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function FittedPoloShirt(props: ProductModelProps) {
  const url = new URL('@resources/3d-models/glb/fitted-polo-shirt.glb', import.meta.url);
  const model = useGLB(url, props.viewerId)
  const elements = model.scene.children
  const [productTexture, fxTexture] = useContext(ProductTextureContext)

  const option_color = props.options.get('color') ?? 'black'
  const color = new Color({
		"white": 0xffffff,
		"black": 0x474143,
		"navy": 0x465b75,
    "sand": 0xfff8eb,
	}[option_color])

  useEffect(() => {
    if (!elements || !elements[2]?.material || !elements[3]?.material || !elements[4]?.material) return

    elements[2].material.color = color
    elements[3].material.color = color
    
    let ao_str = option_color.indexOf("heather") != -1 ? 2 : 0.5
    elements[2].material.aoMapIntensity = ao_str
    elements[3].material.aoMapIntensity = ao_str
    elements[4].material.aoMapIntensity = ao_str
  }, [color, elements])

  // Apply lab texture
  useEffect(() => {
    if (!fxTexture || !elements || !elements[4]?.material) return

    elements[4].material.map = fxTexture
    elements[4].material.map.flipY = false
    elements[4].material.map.needsUpdate = true
  }, [productTexture, elements])

  return <ModelElements elements={elements} />
}