import { Button, ButtonBase, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack, styled } from "@mui/material";
import { ShoppingCartRounded as ShoppingCartRoundedIcon, AccountCircleRounded as AccountCircleRoundedIcon, ArrowDropDownRounded as ArrowDropDownRoundedIcon, DashboardRounded as DashboardRoundedIcon, EditRounded as EditRoundedIcon } from '@mui/icons-material';
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { OpenAuthModal } from "../Auth/_actions/AuthActions";
import { Link } from "react-router-dom";
import AuthDialog from "../Auth/AuthDialog";
import UserStoreStore from "../UserData/_stores/UserStoreStore";
import GoalsAndRewardsDialog from "../Account/GoalsAndRewards/GoalsAndRewardsDialog";

const UserNavMenu = styled(Menu)({
  '.MuiMenuItem-root': {
    backgroundColor: 'transparent',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    }
  }
})

export default function UserNav() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('header')
  const [tR] = useTranslation('routes')

  const name = useAppSelector(state => state.get('userData').get('name'))
  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))
  const validated = useAppSelector(state => state.get('userData').get('validated'))
  const stores = useAppSelector(state => state.get('userData').get('stores'))
  const createUrl = useAppSelector(state => state.get('UIData').get('createUrl'))

  const [menuOpened,setMenuOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [goalsOpened, setGoalsOpened] = useState(false)

  const onOpenLogin = useCallback(() => {
    dispatch(OpenAuthModal(false))
  }, [])

  const onOpenSignup = useCallback(() => {
    dispatch(OpenAuthModal(true))
  }, [])

  const onMenuOpen = useCallback((e:MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setMenuOpened(true)
  }, [])

  const onMenuClose = useCallback(() => {
    setMenuOpened(false)
  }, [])

  const onOpenGoals = useCallback(() => {
    setGoalsOpened(true)
    setMenuOpened(false)
  }, []) 
  const onCloseGoals = useCallback(() => setGoalsOpened(false), [])

  const shortName = useMemo(() => {
    let tmpName = name.split(' ')[0] ?? ''

    if(tmpName.length >= 20) {
      tmpName = tmpName.substring(0, 17)+'...'
    }

    return tmpName
  }, [name])

  const renderStore = useCallback((store:UserStoreStore) => {
    return <MenuItem
      key={store.get('id')}
      component="a"
      href={tR('/d/{{store}}', {store: store.get('slug')})}
    >
      <ListItemIcon>
        <img
          src={require('@resources/img/account/' + store.get('slug') + '-icon_dark.svg?url')}
          width={24}
          height={24}
        />
      </ListItemIcon>
      <ListItemText>{t('{{store}} dashboard', {store: store.get('name')})}</ListItemText>
    </MenuItem>
  }, [t, tR])

  return <Stack direction="row" spacing={1} alignItems="center">
    <Button
      variant="contained"
      color="accent"
      size="large"
      component="a"
      href={tR('/create')+(createUrl !== '' ? '/'+createUrl : '')}
      startIcon={<EditRoundedIcon/>}
      sx={{
        py: 1.25,
        backgroundImage: 'linear-gradient(-180deg, #f600be 0%, #f600be 0%, #ea008a 100%, #ea008a 100%)',
        display: {
          xs: 'none',
          md: 'flex',
        }
      }}
    >{t('Start Creating')}</Button>
    <Stack direction="row" alignSelf="stretch">
      <Button
        variant="text"
        color="white"
        component="a"
        href={tR('/cart')}
        sx={{
          display: 'flex',
          height: '100%',
          fontSize: '1rem',
          borderRadius: '0',
          minWidth: 0,
          alignItems: 'center',
        }}
      ><ShoppingCartRoundedIcon /></Button>
      { loggedIn ? <>
        <Button
          onClick={onMenuOpen}
          variant="text"
          color="white"
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            height: '100%',
            fontSize: '1rem',
            borderRadius: '0',
            minWidth: 0,
            alignItems: 'center',
          }}
          startIcon={shortName ? <AccountCircleRoundedIcon fontSize="large"/> : undefined}
          endIcon={<ArrowDropDownRoundedIcon/>}
        >{shortName ? shortName : <AccountCircleRoundedIcon fontSize="large"/>}</Button>
      </> : <>
        <Button
          onClick={onOpenLogin}
          variant="text"
          color="white"
          sx={{
            display: 'flex',
            height: '100%',
            fontSize: '1rem',
            borderRadius: '0',
          }}
        >{t('Login')}</Button>
        <Button
          onClick={onOpenSignup}
          variant="text"
          color="white"
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            height: '100%',
            fontSize: '1rem',
            borderRadius: '0',
          }}
        >{t('Sign up')}</Button>
      </>}
    </Stack>

    <UserNavMenu
      open={menuOpened}
      onClose={onMenuClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          sx: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: '#000',
            color: '#fff',
          }
        }
      }}
    >
      <MenuItem
        component="a"
        href={tR('/account')}
      >
        <ListItemIcon>
          <DashboardRoundedIcon style={{
            color: '#fff',
          }}/>
        </ListItemIcon>
        <ListItemText>{t('My account')}</ListItemText>
      </MenuItem>

      { validated ? <MenuItem
        onClick={onOpenGoals}
      >
        <ListItemIcon>
          <img 
            src={require('@resources/img/account/rewards/icon_goal.svg?url')}
            width={24}
            height={24}
          />
        </ListItemIcon>
        <ListItemText>{t('Goals & Rewards')}</ListItemText>
      </MenuItem> : null }

      { stores.count() > 0 ? <Divider style={{
        borderColor: 'rgba(255, 255, 255, 0.25)',
      }}/> : null }

      { stores.valueSeq().map(renderStore)}
      <Divider style={{
        borderColor: 'rgba(255, 255, 255, 0.25)',
      }}/>
      <MenuItem
        component="a"
        href={tR('/auth/logout')}
      >{t('Log out')}</MenuItem>
    </UserNavMenu>

    <AuthDialog/>

    <GoalsAndRewardsDialog
      opened={goalsOpened}
      onClose={onCloseGoals}
    />
  </Stack>
}