import { PaletteColorOptions, PaletteOptions, Paper, alpha } from '@mui/material';
import { createTheme, adaptV4Theme, styled, ComponentsVariants } from '@mui/material/styles';
import { ArrowDropDownRounded as ArrowDropDownRoundedIcon, ClearRounded as ClearRoundedIcon } from '@mui/icons-material'

declare module '@mui/material/styles' {
	interface Palette {
		accent: Palette['primary'],
		gray: Palette['primary'],
		white: Palette['primary']
	}

	interface PaletteOptions {
		accent?: PaletteOptions['primary'],
		gray?: PaletteOptions['primary'],
		white?: PaletteOptions['primary']
	}

	interface TypographyVariants {
		'h1-accent': React.CSSProperties
		'h2-accent': React.CSSProperties
	}

	interface TypographyVariantsOptions {
		'h1-accent'?: React.CSSProperties
		'h2-accent'?: React.CSSProperties
	}

	interface BreakpointOverrides {
		xs: true,
		sm: true,
		md: true,
		lg: true,
		xl: true,
		lab_mobile: true,
		dialog_mobile: true,
	}

	interface Theme {
		height_breakpoints: {
			values: {
				lab_mobile: number
			}
		};
	}

	interface ThemeOptions {
		height_breakpoints?: {
			values?: {
				lab_mobile?: number
			}
		};
	}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'h1-accent': true;
		'h2-accent': true;
  }
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
	  accent: true;
	  gray: true;
		white: true;
	}

	interface ButtonPropsVariantOverrides {
		dashed: true;
	}

	interface ButtonPropsSizeOverrides {
		tiny: true;
	}
}

declare module '@mui/material/IconButton' {
	interface IconButtonPropsColorOverrides {
	  accent: true;
	  gray: true;
	}
}

declare module '@mui/material/Chip' {
	interface ChipPropsColorOverrides {
	  accent: true;
	  gray: true;
	}

	interface ChipPropsVariantOverrides {
		'square-filled': true;
	}
}

declare module '@mui/material/Switch' {
	interface SwitchPropsColorOverrides {
		accent: true
	}
}

declare module '@mui/material/LinearProgress' {
	interface LinearProgressPropsColorOverrides {
		accent: true
	}
}

declare module '@mui/material/Divider' {
	interface DividerPropsVariantOverrides {
		'bold': true;
	}
}

declare module '@mui/material/SvgIcon' {
	interface SvgIconPropsColorOverrides {
		accent: true
		gray: true
	}
}

let theme = createTheme({
	typography: {
		fontFamily: [
			'Poppins',
			'Roboto',
			'sans-serif'
		].join(','),
		body1: {
			fontFamily: 'Poppins, Roboto, sans-serif',
		},
		body2: {
			fontSize: '14px',
		},
		subtitle1: {
			textTransform: 'uppercase',
			fontSize: '0.875rem',
			fontWeight: 500,
		},
		caption: {
			color: '#999',
			fontSize: '12px',
			lineHeight: '1.5',
		},
		h1: {
			fontFamily: "'Bebas Neue', sans-serif",
			fontSize: '3.7rem',
			color: '#323232',
			lineHeight: '3.3rem',
		},
		'h1-accent': {
			color: '#f600be',
			fontFamily: 'Rottersand, serif',
			fontSize: '4rem',
			fontStyle: 'normal',
			fontWeight: 'normal',
		},
		h2: {
			fontFamily: 'Bebas Neue, Poppins, sans-serif',
			fontSize: '48px',
			color: '#000',
			lineHeight: '1em',
		},
		'h2-accent': {
			color: '#f600be',
			fontFamily: 'Rottersand, serif',
			fontSize: '2.5rem',
			fontStyle: 'normal',
			fontWeight: 'normal',
		},
		h3: {
			fontSize: '18px',
			textTransform: 'uppercase',
			fontWeight: 600,
		},
		h4: {
			fontSize: '16px',
			lineHeight: 'normal',
			color: '#000',
			fontWeight: 600,
			letterSpacing: 'normal',
		},
		h5: {
			fontSize: '15px',
		}
	},
});

theme = createTheme(theme, {
	palette: {
		primary: {
			main: '#00aced',
			contrastText: '#fff',
		},
		secondary: {
			main: '#000',
			dark: '#333',
			light: '#555',
			contrastText: '#fff',
		},
		accent: theme.palette.augmentColor({
			color: {
				main: '#f600be',
				contrastText: '#fff',
			},
			name: 'accent',
		}),
		gray: theme.palette.augmentColor({
			color: {
				main: '#ebebeb',
			},
			name: 'gray',
		}),
		white: theme.palette.augmentColor({
			color: {
				main: '#fff',
			},
			name: 'white',
		}),
	},
});

export default createTheme(theme, {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
			lab_mobile: 1053,
			dialog_mobile: 760,
		}
	},
	height_breakpoints: {
		values: {
			lab_mobile: 780,
		}
	},
	shape: {
		borderRadius: 20,
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					WebkitFontSmoothing: 'auto',
				},
			}
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'dashed' },
					style: {
						border: '1px dashed',
					}
				},
				{
					props: { size: 'large' },
					style: {
						padding: '8px 26px',
						//fontSize: '1rem',
						fontSize: '15px',
					}
				},
				{
					props: { size: 'large', variant: 'dashed' },
					style: {
						padding: '7px 25px',
					}
				},
				{
					props: { size: 'large', variant: 'outlined' },
					style: {
						borderWidth: 2,
						padding: '6px 24px',
						'&:hover': {
							borderWidth: 2,
						},
						'&:disabled': {
							borderWidth: 2,
						}
					}
				},
				{
					props: { size: 'medium' },
					style: {
						padding: '6px 16px',
						fontSize: '13px',
					}
				},
				{
					props: { size: 'medium', variant: 'dashed' },
					style: {
						padding: '5px 15px',
					}
				},
				{
					props: { size: 'medium', variant: 'outlined' },
					style: {
						borderWidth: 2,
						padding: '4px 14px',
						'&:hover': {
							borderWidth: 2,
						},
						'&:disabled': {
							borderWidth: 2,
						}
					}
				},
				{
					props: { size: 'small' },
					style: {
						padding: '4px 15px',
						fontSize: '12px',
					}
				},
				{
					props: { size: 'small', variant: 'dashed' },
					style: {
						padding: '3px 14px',
					}
				},
				{
					props: { size: 'small', variant: 'outlined' },
					style: {
						padding: '3px 14px',
					}
				},
				{
					props: { size: 'tiny' },
					style: {
						//fontSize: '0.725rem',
						fontSize: '11px',
						padding: '2px 12px',
						textTransform: 'uppercase',
						'.MuiButton-startIcon > *:nth-of-type(1)': {
							fontSize: '14px'
						}
					}
				},
				{
					props: { size: 'tiny', variant: 'dashed' },
					style: {
						padding: '1px 11px',
					}
				},
				{
					props: { size: 'tiny', variant: 'outlined' },
					style: {
						padding: '1px 11px',
					}
				}
			],
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: ({ ownerState, theme, test }) => ({
					borderRadius: 25,
					textTransform: 'none',
					...(ownerState.variant === 'dashed' && {
						borderColor: ownerState.color === 'inherit' ? undefined : alpha(theme.palette[ownerState.color].main, 0.5),
						color: ownerState.color === 'inherit' ? undefined : theme.palette[ownerState.color].main,
						'&:hover': {
							borderColor: ownerState.color === 'inherit' ? undefined : theme.palette[ownerState.color].main,
							backgroundColor: ownerState.color === 'inherit' ? undefined : alpha(theme.palette[ownerState.color].main, 0.04),
						},
						...ownerState.size === 'small' && {
							fontSize: '0.8125rem',
						},
						...ownerState.size === 'medium' && {
							padding: '5px 15px',
							fontSize: '0.875rem',
						},
						...ownerState.size === 'large' && {
							padding: '7px 21px',
							fontSize: '0.9375rem',
						}
					})
				}),
				outlined: ({ ownerState, theme, test }) => ({
					borderColor: ownerState.color === 'inherit' ? undefined : theme.palette[ownerState.color].main,
					'&:hover': {
						backgroundColor: ownerState.color === 'inherit' ? undefined : alpha(theme.palette[ownerState.color].main, 0.15),
					}
				}),
			}
		},
		MuiIconButton: {
			variants: [
				{
					props: { size: 'large' },
					style: {
						fontSize: '26px',
						'> .MuiSvgIcon-fontSizeMedium': {
							fontSize: '22px',
						},
						'> .MuiSvgIcon-fontSizeLarge': {
							fontSize: '26px',
						},
					}
				},
				{
					props: { size: 'medium' },
					style: {
						fontSize: '22px',
						'> .MuiSvgIcon-fontSizeMedium': {
							fontSize: '22px',
						},
						'> .MuiSvgIcon-fontSizeLarge': {
							fontSize: '26px',
						},
					}
				},
			]
		},
		MuiCard: {

		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 24,
				}
			}
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					background: 'rgba(0, 0, 0, 0.06)',
					justifyContent: 'space-between',
    				padding: 0,
				}
			}
		},
		MuiChip: {
			variants: [
				{
					props: { variant: 'square-filled' },
					style: {
						borderRadius: 0,
					}
				},
				{
					props: { size: 'small' },
					style: {
						height: 'auto',
						lineHeight: '1.75',
						fontSize: '0.725rem',
						padding: '2px 0',
						textTransform: 'uppercase',
					}
				}
			],
			styleOverrides: {
				root: {
					lineHeight: 'normal'
				}
			}
		},
		MuiAlert: {
			variants: [
				{
					props: { variant: 'standard' },
					style: {
						borderRadius: 10,
					}
				},
				{
					props: { variant: 'outlined', severity: 'info' },
					style: {
						backgroundColor: '#e5f6fd',
					}
				},
				{
					props: { variant: 'outlined', severity: 'warning' },
					style: {
						backgroundColor: '#fff4e5',
					}
				},
				{
					props: { variant: 'outlined', severity: 'success' },
					style: {
						backgroundColor: '#edf7ed',
					}
				},
				{
					props: { variant: 'outlined', severity: 'error' },
					style: {
						backgroundColor: '#fdeded',
					}
				}
			]
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderRadius: 50,
					'&::after': {
						display: 'none',
					},
					'&::before': {
						display: 'none',
					},
				},
				input: {
					padding: '25px 20px 8px',
				},
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				filled: ({ ownerState }) => ({
				...(ownerState.shrink ? {
						transform: 'translate(20px, 7px) scale(0.75)',
					} : {
						transform: 'translate(20px, 16px) scale(1)',
					}),
				})
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
				fullWidth: true,
			},
			variants: [
				{
					props: { variant: 'standard' },
					style: {
						'.MuiInputLabel-root': {
							transform: 'none',
							marginBottom: '5px',
    					color: '#000',
							position: 'static',
						},
						'label + .MuiInputBase-root': {
							marginTop: 0,
						}
					}
				}
			],
		},
		MuiInput: {
			defaultProps: {
				disableUnderline: true,
			},
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(0, 0, 0, 0.06)',
					borderRadius: '30px',
					transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
					padding: '10px 16px',

					'&.MuiInputBase-sizeSmall': {
						padding: '7px 16px',
					},

					'&:not(.Mui-disabled)': {
						'&:hover, &:focus-within': {
							backgroundColor: 'rgba(0, 0, 0, 0.12)',
						}
					}
				},
				input: {
					//borderRadius: 'inherit',
					padding: 0
				}
			}
		},
		MuiAutocomplete: {
			defaultProps: {
				componentsProps: {
          paper: {
            elevation: 8,
          }
        },
				popupIcon: <ArrowDropDownRoundedIcon/>,
				clearIcon: <ClearRoundedIcon fontSize="small"/>
			},
			styleOverrides: {
				listbox: {
					'&::-webkit-scrollbar': {
						width: '10px',
						height: '10px'
					},
					
					'&::-webkit-scrollbar-track': {
						backgroundColor: 'transparent'
					},
					
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: 'rgba(0, 0, 0, 0.15)',
						borderRadius: '10px',
					
						'&:hover': {
						  backgroundColor: 'rgba(0, 0, 0, 0.25)'
						}
					}
				},
				inputRoot: {
					paddingTop: '10px',
					paddingBottom: '10px',
					'.MuiInput-input': {
						//padding: '10px 20px',
						padding: 0,
					}
				},
				endAdornment: {
					right: '16px',
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					alignItems: 'flex-start',

					'.MuiRadio-root + .MuiFormControlLabel-label, .MuiCheckbox-root + .MuiFormControlLabel-label': {
						paddingTop: '9.5px',
					},
					'.MuiSwitch-root + .MuiFormControlLabel-label': {
						paddingTop: '8px',
					}
				},
			}
		},
		MuiRadio: {
			defaultProps: {
				color: 'secondary'
			}
		},
		MuiSwitch: {
			variants: [
				{
					props: { color: 'accent' },
					style: {
						'& .MuiSwitch-switchBase.Mui-checked': {
							color: theme.palette.accent.main,
							'&:hover': {
							  backgroundColor: alpha(theme.palette.accent.main, theme.palette.action.hoverOpacity),
							},
						},
						'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
							backgroundColor: theme.palette.accent.main,
						},
					}
				}
			]
		},
		MuiSelect: {
			defaultProps: {
				IconComponent: ArrowDropDownRoundedIcon
			}
		},
		/*MuiSelect: {
			defaultProps: {
				variant: 'standard'
			},
			styleOverrides: {
				inputRoot: {
					paddingTop: '10px',
					paddingBottom: '10px',
					'.MuiInput-input': {
						//padding: '10px 20px',
						padding: 0,
					}
				},
				icon: {
					right: '16px',
				}
			}
		},*/
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					//marginTop: 0,
					//marginBottom: '-16px',
					//lineHeight: '16px',
				}
			}
		},
		MuiDivider: {
			variants: [
				{
					props: { variant: 'bold' },
					style: {
						//borderBottomWidth: '2px',
						borderColor: 'rgba(0, 0, 0, 0.4)'
					}
				},
			]
		},
		MuiDialog: {
			variants: [
				{
					props: { maxWidth: 'lg' },
					style: {
						'.MuiDialogTitle-root': {
							padding: '32px 60px 0 32px',

							[theme.breakpoints.down('sm')]: {
								padding: '16px 60px 0 24px',
							},
						},
						'.MuiDialogContent-root': {
							padding: '32px',
							
							[theme.breakpoints.down('sm')]: {
								padding: '20px 24px',
							}
						},
						'.MuiDialogTitle-root+.MuiDialogContent-root': {
							paddingTop: 0,
						}
					}
				},
				{
					props: { maxWidth: 'md' },
					style: {
						'.MuiDialogTitle-root': {
							padding: '32px 60px 0 40px',
							
							[theme.breakpoints.down('sm')]: {
								padding: '16px 60px 0 24px',
							},
						},
						'.MuiDialogContent-root': {
							padding: '40px',
							
							[theme.breakpoints.down('sm')]: {
								padding: '20px 24px',
							}
						},
						'.MuiDialogTitle-root+.MuiDialogContent-root': {
							paddingTop: 0,
						}
					}
				}	
			],
			styleOverrides: {
					
				paper: {
					//borderRadius: 30
				}
			}
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					//padding: '40px',
				}
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					fontFamily: 'Bebas Neue',
					fontSize: '32px',
					lineHeight: 'normal',
					padding: '16px 24px 0',
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: '#000',
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					height: 10,
					borderRadius: 20,
				}
			}
		}
	}
});