import { West as WestIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { Button, CssBaseline, FormControl, GlobalStyles, InputBase, MenuItem, Paper, Select, SelectChangeEvent, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { GetProductLabData } from '../../UIData/_actions/DesignLabActions';
import { ClearLab, EditActiveDesign, EditActiveOption, EditActiveProduct, EditActiveVariant, EditAutoDesignSubproduct, EditDesignName, EditLayer, EditMirrorMode, ResetActiveOptions, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import { EditLoader } from '../../UIData/_actions/UIDataActions';
import { FetchOneItem } from '../../UserData/_actions/UserDataActions';
import { LAB_FONTS } from '../../config';
import FabricLab from '../FabricLab/_components/FabricLab';
import AutoDesignOverlay from './AutoDesignOverlay';
import AutoDesignTool from './AutoDesignTool';
import ExitLab from './ExitLab';
import FullPageLabLoader from './FullPageLabLoader';
import LabStage from '../Stage/LabStage';
import MirrorTool from './MirrorTool';
import PreviewViewer from './PreviewViewer';
import ProductSelector from '../ProductSelector/ProductSelector';
import SaveBar from '../Save/SaveBar';
import SceneSelector from '../SceneSelector/SceneSelector';
import SubproductSelector from '../SubproductSelector/SubproductSelector';
import ZoomControls from './ZoomControls';
import IconButton from '../../Common/Buttons/IconButton';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import LayerSection from '../Layer/LayerSection';
import LabFabricViewer from '../FabricLab/_components/LabFabricViewer';
import CookieConsent from '../../Header/CookieConsent';
import ProductVariantStore from '../../AppData/_stores/ProductVariantStore';
import VariantSelector from './VariantSelector';
import useLabInit from '../_hooks/useLabInit';
import LabDevTool from '../DevTool/LabDevTool';
import LabInterface from './LabInterface';
import Toast from '../../UIData/_components/Toast';

const DesignLabAppContainer = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1000,
  background: '#F3F3F3',
  display: 'flex',
  flexDirection: 'column',
})

export const InputSelect = styled(InputBase)({
  height: '100%',
  color: '#fff',

  '.MuiInputBase-input:focus': {
    background: 'transparent',
  },

  '.MuiSelect-select': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 34px 0 0',
  },

  '.MuiSelect-icon': {
    color: '#fff',
  }
})

const YellowDecoration = styled('div')({
  overflow: 'hidden',
  maxWidth: '80px',
  maxHeight: '120px',
  position: 'absolute',
  zIndex: -1,
  right: 0,
  bottom: 0,

  '&:before': {
    content: '""',
    height: '150px',
    width: '150px',
    border: '20px solid #FCF57F',
    display: 'block',
    transform: 'rotate(-20deg)',
    marginLeft: '30px',
  }
})

const PinkDecoration = styled('div')({
  overflow: 'hidden',
  maxWidth: '80px',
  maxHeight: '160px',
  position: 'absolute',
  zIndex: -1,

  '&:before': {
    content: '""',
    height: '150px',
    width: '60px',
    backgroundColor: '#F396D6',
    display: 'block',
    transform: 'rotate(25deg)',
    marginLeft: 0,
  },

  '&.right': {
    right: 0,
    bottom: 0,
    top: '25vh',

    '&:before': {
      margin: '0 -40px 30px 30px',
    },
  },

  '&.left': {
    left: 0,
    bottom: 0,

    '&:before': {
      margin: '30px 30px 30px -40px',
    }
  }
})

type Props = {}

export type SaveState = 'none' | 'generating' | 'create_more' | 'done';

export default function DesignLab(props: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const isEditing = useMemo(() => {
    return searchParams.has('design');
  }, [searchParams]);

  return (
    <CssBaseline>
      <GlobalStyles
        styles={{
          html: {
            overscrollBehavior: 'none',
            overflow: 'hidden',
          }
        }}
      />

      <DesignLabAppContainer>
        <YellowDecoration />
        <PinkDecoration className="left" />
        <PinkDecoration className="right" />
        <div style={{
          height: '64px',
          background: '#000',
          color: '#fff',
          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 5,
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <h1 style={{
              margin: '0 15px 0 15px',
              height: '40px',
            }}><img src={require('@resources/img/create/design-lab-v4/logo-design-lab.svg?url')} alt="Design Lab" style={{ width: 'auto', height: '100%' }} /></h1>
            <div style={{
              width: '1px',
              height: '36px',
              background: '#fff',
              marginLeft: '10px',
              marginRight: '10px',
            }}></div>
            {!isEditing ? <>
              <ProductSelector />
              <VariantSelector />
            </> : null}
          </div>

          <div style={{
            display: 'flex',
            alignSelf: 'stretch',
          }}>
            <LabDevTool />
            <ExitLab />
          </div>
        </div>

        <LabInterface />
      </DesignLabAppContainer>
      <CookieConsent />

      <Toast/>
    </CssBaseline>
  );
}