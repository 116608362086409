import { useContext, useEffect } from "react"
import { Color } from "three"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function VeganLeatherBackpack(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/vegan-leather-backpack.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children

    const [productTexture] = useContext(ProductTextureContext)
    const option_color = props.options.get('color') ?? 'black'

    useEffect(() => {
        if(!elements || !elements[0]?.material || !elements[1]?.material) return

        elements[0].material.color = option_color == "black" ? new Color(0x272525) : new Color(0x80422b)
        elements[4].material.color = option_color == "black" ? new Color(0x272525) : new Color(0x663523)
	    elements[1].material.color = option_color == "black" ? new Color(0x999999) : new Color(0xD5B27C)

        elements[0].material.roughness = 1.2
    }, [option_color])

    useEffect(() => {
        if(!elements || !elements[0]?.material || !elements[3]?.material) return

        //elements[0].material.normalScale = new Vector2(2.5,-2.5)
        //elements[3].material.normalScale = new Vector2(2.5,-2.5)
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[3]?.material) return

        elements[3].material.map = productTexture
        elements[3].material.map.flipY = false
        elements[3].material.map.needsUpdate = true
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}