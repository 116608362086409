import { Button, Card, CardContent, CardMedia, IconButton, LinearProgress, Paper, Snackbar, SnackbarContent, Stack, styled, Typography } from '@mui/material';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConsumeToast } from '../_actions/UIDataActions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import GoalToast from '../../Account/GoalsAndRewards/GoalToast';

const CloseBtnText = styled('p')({
	fontFamily: 'Poppins',
	fontStyle: 'normal',
	fontWeight: 'bold',
	fontSize: '0.95rem',
	lineHeight: '15px',
	letterSpacing: '0.11em',
	textTransform: 'uppercase',
	color: '#FFFFFF',
	margin: '0',
});

export default function Toast() {
	const dispatch = useAppDispatch();
	const toast = useAppSelector(state => state.get('UIData').get('toast'));

	const [opened, setOpened] = useState(false);

	useEffect(() => {
		if (toast.get('new')) {
			setOpened(true);
		}
	}, [toast]);

	const onClose = useCallback(() => {
		setOpened(false)
		setTimeout(() => {
			dispatch(ConsumeToast())
		}, 500)
	}, [])

	const goalData = useMemo(() => toast.get('goalData'), [toast]);

	return goalData ? <GoalToast
		open={opened}
		onClose={onClose}
		toastKey={toast.get('key')}
		goalData={goalData}
	/> : <Snackbar
		open={opened}
		key={toast.get('key')}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		message={toast.get('message')}
		autoHideDuration={3000}
		onClose={onClose}
		action={
			<IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
				<CloseBtnText>Ok</CloseBtnText>
			</IconButton>
		}
	/>
}