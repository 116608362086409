import { Box, Grid, Stack, styled, Tab, Tabs, Typography } from "@mui/material"
import { TabPanel, TabList, TabContext } from '@mui/lab'
import { useAppSelector } from "../../Common/_hooks/useAppSelector"
import Dialog from "../../Common/Dialog/Dialog"
import { SyntheticEvent, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Goal from './Goal'
import GoalStore from "../../AppData/_stores/GoalStore"
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch"
import { FetchUserGoals } from "../../UserData/_actions/UserGoalActions"
import { FetchUserRewards } from "../../UserData/_actions/UserRewardActions"
import DialogContent from "../../Common/Dialog/DialogContent"
import { selectUnusedRewards } from "../../UserData/_selectors/UserRewardSelectors"
import UserRewardCoupon from "./UserRewardCoupon"
import UserRewardStore from "../../UserData/_stores/UserRewardStore"
import GoalList from "./GoalList"

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)'
    }
  }
}))

const StyledTabList = styled(TabList)(({ theme }) => ({
  marginTop: theme.spacing(3),

  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-evenly',
  },
  
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '5px',
  },
  '& .indicator-span': {
    maxWidth: '50px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '24px',

}))

const StyledTabPanel = styled(TabPanel)({
  display: 'flex',
  minHeight: 0,
  padding: 0,
  flex: 1,

  '&[hidden]': {
    display: 'none',
  }
})

type Props = {
  opened: boolean
  onClose: () => void
}

export default function GoalsAndRewardsDialog(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [tab, setTab] = useState<'goals' | 'rewards'>('goals')
  const userRewards = useAppSelector(selectUnusedRewards)
  const rewards = useAppSelector(state => state.get('appData').get('rewards'))

  useEffect(() => {
    dispatch(FetchUserGoals())
    dispatch(FetchUserRewards())
  }, [])

  const onChangeTab = useCallback((event: SyntheticEvent, value: 'goals' | 'rewards') => {
    setTab(value)
  }, [])

  const renderReward = useCallback((userReward: UserRewardStore) => {
      const reward = rewards.get(String(userReward.get('id_reward')))
      return reward ? <Grid key={userReward.get('id')} item xs={12} md={6}>
        <UserRewardCoupon
          reward={reward}
          fullWidth
        />
      </Grid> : null
    }, [rewards])

  return <Dialog
    open={props.opened}
    maxWidth="md"
    fullWidth
    onClose={props.onClose}
    showCloseButton
    ornaments="shapes-3"
    sx={{
      '.MuiDialog-paper': {
        maxHeight: '800px',
        height: '90%',
      }
    }}
  >
    <TabContext value={tab}>
      <StyledTabList 
        onChange={onChangeTab} 
        TabIndicatorProps={{ children: <span className="indicator-span" /> }}
      >
        <StyledTab
          disableRipple 
          label={t('Goals')} 
          value="goals" 
        />
        <StyledTab 
          disableRipple
          label={t('Rewards')} 
          value="rewards" 
        />
      </StyledTabList>
      <StyledTabPanel value="goals">
        <GoalList/>
      </StyledTabPanel>
      <StyledTabPanel value="rewards">
        <StyledDialogContent style={{ paddingTop: '16px'}}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant="body1" sx={{fontWeight: 700}}>{t('You will be able to apply your reward to your order when you get to checkout.')}</Typography>
              <Typography variant="body1">{t('Only 1 reward can be used per order. Product discounts cannot be applied to wholesale.')}</Typography>
            </Stack>
            <div>
              <Grid container spacing={2}>
                {userRewards.valueSeq().map(renderReward)}
              </Grid>
            </div>
          </Stack>
        </StyledDialogContent>
      </StyledTabPanel>
    </TabContext>
  </Dialog>
}