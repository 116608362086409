import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../../Common/_components/CheckBoxes';

const PRODUCT_ID = 131;
const VIEW_PRODUCT_ID = 153;

export default function CoasterPage() {
  const [t] = useTranslation('page/products/coasters');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Coasters | Products')}
      description={t('Design and create your own custom printed Coasters featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#d2b5ff"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '52%',
      }}
      tag={t('The all-over print')}
      title={t('Coaster')}
      text={t('Protect your surfaces in style with custom printed coasters! Made from sturdy PVC, these standard size coasters feature vibrant, permanent sublimation prints on the front and a textured black backing for grip. A sleek edge stitch adds a polished finish. Printed on-demand, each coaster is made to order for you or for your product line.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/coasters/banner-1-' + locale + '.png')}
      alt={t('Picture of Coaster')}
      details={[
        {
          img: require('@resources/img/pages/products/coasters/icon-1.svg?url'),
          alt: t('Coaster icon'),
          text: t('Rigid PVC 4mm thick coaster'),
        },
        {
          img: require('@resources/img/pages/products/coasters/icon-2.svg?url'),
          alt: t('Heat and moisture icon'),
          text: t('Protects against heat and moisture'),
        },
        {
          img: require('@resources/img/pages/products/coasters/icon-3.svg?url'),
        alt: t('Rubberized texture icon'),
          text: t('Non-slip black textured back'),
        },
        {
          img: require('@resources/img/pages/products/coasters/icon-4.svg?url'),
          alt: t('Print icon'),
          text: t('Durable and vibrant sublimation printed front that’s easy to clean'),
        },
        {
          img: require('@resources/img/pages/products/coasters/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Made on demand in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/coasters/gallery_0.jpg'),
      require('@resources/img/pages/products/coasters/gallery_1.jpg'),
      require('@resources/img/pages/products/coasters/gallery_2.jpg'),
      require('@resources/img/pages/products/coasters/gallery_3.jpg'),
      require('@resources/img/pages/products/coasters/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Coaster<br/> <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Coaster! At prices designed for reselling, Coaster made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      listOptions
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Coaster Sizing')}
      text={t('Available in 1 size:  3.75” by 3.75”, 0.15” thick (9.5 cm by 9.5 cm square, 0.4 cm thick)')}
    >
      <img
        src={require('@resources/img/pages/products/coasters/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <SectionBranding
      title={t('Brand your Coaster')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Coaster with these great products to create your own unique styles.')}
      productSlugs={['organic-cotton-hemp-tea-towels', 'desk-mat', 'stainless-steel-water-bottle']}
    />
  </div>
}
