import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import BetterAnimFrame from "../Common/Utils/BetterAnimFrame";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import ProductTextureContext from "./_contexts/ProductTextureContext";
import useCustomProductTexture from "./_hooks/useCustomProductTexture";
import { CanvasTexture } from "three";
import useRenderingTrace from "../Common/_hooks/useRenderingTrace";
import { LabData } from "../AppData/LabData";

const TEXTURE_UPDATE_FPS = 30;
const TEXTURE_UPDATE_MIN_INTERVAL = 1000/TEXTURE_UPDATE_FPS;
const TEXTURE_MAX_RESOLUTION = 1024;

interface Props {
  renderTexture: () => HTMLCanvasElement | OffscreenCanvas | HTMLImageElement | undefined
  productId: number,
  labData: LabData,
  options: Immutable.Map<string, string>
  variant: string,
  setProductTexture: (texture:CanvasTexture) => void
  setFxTexture: (texture:CanvasTexture) => void
}

export default function ModelViewerTextureUpdater(props: Props) {
  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'))
  const autoDesign = useAppSelector(state => activeSubproduct ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : undefined)
  const layers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'))
  const mirrorModes = useAppSelector(state => state.get('UIData').get('designLab').get('mirrorModes'))
  const textureUpdate = useAppSelector(state => state.get('UIData').get('designLab').get('textureUpdate'))

  const [productTexture, fxTexture] = useContext(ProductTextureContext)
  const customProductTexture = useCustomProductTexture(props.productId, props.labData)
  const productTextureCanvas = useRef<OffscreenCanvas>()
  const fxTextureCanvas = useRef<OffscreenCanvas|null>(null)

  const lastFrameTime = useRef(0);
  const updateTimeout = useRef<NodeJS.Timeout | null>(null);

  const textureResolution = useMemo(() => {
    return Math.min(props.labData.variants[props.variant].dimensions.texture.width, TEXTURE_MAX_RESOLUTION) / props.labData.variants[props.variant].dimensions.texture.width;
  }, [props.labData, props.variant])

  const maxAnisotropy = useMemo(() => {
    const canvas = new OffscreenCanvas(0, 0)
    const gl = canvas.getContext('webgl2') ?? canvas.getContext('webgl');
    if(!gl) return 1;

    const ext =
      gl.getExtension("EXT_texture_filter_anisotropic") ||
      gl.getExtension("MOZ_EXT_texture_filter_anisotropic") ||
      gl.getExtension("WEBKIT_EXT_texture_filter_anisotropic");

    if (ext) {
      return gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
    } else {
      return 1;
    }
  }, [])

  const updateTexture = useCallback((timestamp:number) => {
    const deltaFrameTime = timestamp - lastFrameTime.current;

    if(updateTimeout.current) clearTimeout(updateTimeout.current);

    //Drop frames if we're updating too fast
    if(deltaFrameTime < TEXTURE_UPDATE_MIN_INTERVAL) {
      //Auto trigger an update if it times out to ensure we have the last frame
      updateTimeout.current = setTimeout(() => {
        BetterAnimFrame(60)(updateTexture);
      }, TEXTURE_UPDATE_MIN_INTERVAL - deltaFrameTime);
      return;
    }

    lastFrameTime.current = timestamp;
    
    if (!props.renderTexture || !productTextureCanvas.current || !props.labData || !props.labData.variants[props.variant]) {
      return;
    }

    let context = productTextureCanvas.current.getContext("2d")
    if (context === null) return;

    const texture = props.renderTexture();
    if (!texture) return;

    context.clearRect(0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height);

    if (!props.labData.transparent_texture) {
      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height);
    }

    context.globalCompositeOperation = "source-over"

    const optionObject = props.options.toJS()
    const scenes = Object.keys(props.labData.variants[props.variant].scenes)

    for (let scene of scenes) {
      customProductTexture.pre(productTextureCanvas.current, fxTextureCanvas.current, scene, textureResolution, optionObject, props.variant)
    }

    context.drawImage(texture, 0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height)

    for (let scene of scenes) {
      context.globalCompositeOperation = "destination-over"
      customProductTexture.post(productTextureCanvas.current, fxTextureCanvas.current, scene, textureResolution, optionObject, props.variant)
      context.globalCompositeOperation = "source-over"
    }

    if (productTexture) productTexture.needsUpdate = true
    if (fxTexture) fxTexture.needsUpdate = true
  }, [productTexture, fxTexture, customProductTexture, props.renderTexture, props.labData, props.options, props.variant, textureResolution])

  useEffect(() => {
    if (!props.labData) return;

    const width = props.labData.variants[props.variant].dimensions.texture.width * textureResolution;
    const height = props.labData.variants[props.variant].dimensions.texture.height * textureResolution;

    productTextureCanvas.current = new OffscreenCanvas(width, height)

    let context = productTextureCanvas.current.getContext('2d');
    if (context === null) return;

    if (props.labData.transparent_texture) {
      context.clearRect(0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height);
    } else {
      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, productTextureCanvas.current.width, productTextureCanvas.current.height);
    }

    const newProductTexture = new CanvasTexture(productTextureCanvas.current)
    newProductTexture.colorSpace = "srgb"
    newProductTexture.anisotropy = maxAnisotropy;

    if (props.labData.useFxCanvas) {
      fxTextureCanvas.current = new OffscreenCanvas(width, height)

      const fxTexture = new CanvasTexture(fxTextureCanvas.current)
      fxTexture.colorSpace = "srgb"
      fxTexture.anisotropy = maxAnisotropy;
      props.setFxTexture(fxTexture);
    }

    props.setProductTexture(newProductTexture)
  }, [props.labData?.slug, props.variant, props.setProductTexture, props.setFxTexture, maxAnisotropy, textureResolution])

  //Regular texture updates
  useEffect(() => {
    BetterAnimFrame(60)(updateTexture)
  }, [textureUpdate, updateTexture, layers, mirrorModes, props.variant, activeSubproduct, autoDesign])

  return null;
}