import { useCallback, useContext, useEffect } from "react";
import ModelElements from "../ModelElements";
import { ProductModelProps } from "../ModelViewerProduct";
import ProductTextureContext from "../_contexts/ProductTextureContext";
import useGLB from "../_hooks/useGLB";
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools";

export default function Notebook(props: ProductModelProps) {
    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return {
            'large': new URL('@resources/3d-models/glb/notebook-large.glb', import.meta.url),
            'small': new URL('@resources/3d-models/glb/notebook-small.glb', import.meta.url),
        }[props.variant]
    }, [props.variant])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture, fxTexture] = useContext(ProductTextureContext)

    useEffect(() => {
        // Hide Stretcher
        let obj = FindObject("stretcher", elements)
        if (!obj) return
        obj.visible = false

        // Setup page colors
        let mat = FindMaterial("first page", elements)
        if (mat) mat.color.setHex(0xf2f0e6)
        
        mat = FindMaterial("pages front", elements)
        if (mat) mat.color.setHex(0xf2f0e6)
        
        mat = FindMaterial("pages side", elements)
        if (mat) mat.color.setHex(0xf2f0e6)
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if (!elements || !fxTexture) return

        let mat = FindMaterial("cover out", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }

        mat = FindMaterial("first page", elements)
        if(mat){
            mat.map = fxTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, fxTexture, elements])
    
    return <ModelElements elements={elements}/>
}