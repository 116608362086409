import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { use } from "i18next";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { useMemo } from "react";
import CheckIcon from '@mui/icons-material/Check'

type GoalProps = {
  id: number;
};

export default function Goal(props: GoalProps) {
  const [t] = useTranslation('account-v2');
  const goal = useAppSelector(state => state.get('appData').get('goals').get(String(props.id)));
  const userGoal = useAppSelector(state => state.get('userData').get('goals').get(String(props.id)));
  const rewards = useAppSelector(state => state.get('appData').get('rewards').filter(reward => goal?.get('listRewards').includes(reward.get('id'))), {
    equalityFn: (a, b) => a.equals(b),
  })
  const userRewards = useAppSelector(state => state.get('userData').get('rewards').filter(userReward => goal?.get('listRewards').includes(userReward.get('id_reward'))), {
    equalityFn: (a, b) => a.equals(b),
  })

  const progress = useMemo(() => userGoal?.get('progress') ?? 0, [userGoal]);
  const isCompleted = useMemo(() => goal ? progress >= goal.get('total') : false, [progress, goal]);
  const rewardsUsed = useMemo(() => rewards
    .reduce((reduction, reward) => reduction && !!userRewards
      .find(userReward => userReward.get('id_reward') === reward.get('id'))
      ?.get('used'), true), [rewards, userRewards])

  if (!goal) return null;

  return (
    <Grid item sm={6} md={6} lg={4}>
      <Stack spacing={1} sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%', position: 'relative', textAlign: 'center' }}>
        <Stack alignItems="center">
          <img
            src={require('@resources/img/account/rewards/goals/' + goal.get('slug') + (!isCompleted ? '_inactive' : '') + '.svg?url')}
            alt=""
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
          <Typography variant="h6" sx={{fontSize: '14px'}} dangerouslySetInnerHTML={{ __html: goal.get('name') }} />
        </Stack>

        <Tooltip 
          arrow
          title={<Stack spacing={1}>
            { rewards.valueSeq().map(reward => {
                const userReward = userRewards.find(userReward => userReward.get('id_reward') === reward.get('id'))

                return <Stack 
                  key={reward.get('id')}
                  direction="row" 
                  spacing={1}
                >
                  <Box 
                    component="div"
                    sx={{
                      borderRadius: '50%',
                      width: '16px',
                      height: '16px',
                      border: '1px solid #a6a6a6',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexShrink: 0,
                    }}
                  >
                    { userReward?.get('used') ? <CheckIcon style={{fontSize: '10px'}}/> : null }
                  </Box>
                  <Box 
                    component="div" 
                    dangerouslySetInnerHTML={{ __html: reward.get('name')}}
                    sx={{
                      fontSize: '12px',
                      textDecoration: userReward?.get('used') ? 'line-through' : '',
                      '> span': {
                        fontWeight: 700,
                      }
                    }}
                  />
                </Stack>
            })}
          </Stack>}>
          <Stack direction="row" sx={{ height: '40px', alignItems: 'center', justifyContent: 'center' }}>
            {!rewardsUsed
              ? <img
                src={require('@resources/img/account/rewards/icon_goal.svg?url')}
                style={{
                  filter: isCompleted ? '' : 'grayscale(1)',
                  marginRight: isCompleted ? '-20px' : '0',
                  position: 'relative',
                  zIndex: 10,
                  height: '40px',
                  width: 'auto',
                }}
              />
              : null}

            {isCompleted ? <Box
              component="div"
              sx={{
                border: '2px solid #a6a6a6',
                borderColor: rewardsUsed ? '#a6a6a6' : '#d4ba19',
                backgroundColor: rewardsUsed ? '#dbdbdb' : '#fbf374',
                borderRadius: 20,
                fontSize: '12px',
                fontWeight: 700,
                color: rewardsUsed ? '#444' : '#ab9300',
                pr: 1.5,
                py: 0.5,
                pl: rewardsUsed ? 1.5 : 3,
              }}
            >
              {rewardsUsed
                ? t('Reward claimed')
                : t('Reward unlocked')}
            </Box> : null}
          </Stack>
        </Tooltip>

        <Box
          component="div"
          sx={{
            backgroundColor: '#fff',
            border: '2px solid #4d4d4d',
            borderRadius: '24px',
            color: '#4d4d4d',
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: '-2px',
            padding: '1px 14px 0 10px',
            position: 'absolute',
            right: 0,
            textAlign: 'center',
            top: 0,
          }}
        >{progress} / {goal.get('total')}</Box>
      </Stack>
    </Grid>
  );
}
