import { useCallback, useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function DuvetCover(props: ProductModelProps) {
    const option_size = props.options.get('size') ?? 'twin'

    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_size ? {
            'twin': new URL('@resources/3d-models/glb/duvet-cover-twin.glb', import.meta.url),
            'full': new URL('@resources/3d-models/glb/duvet-cover-full.glb', import.meta.url),
            'king': new URL('@resources/3d-models/glb/duvet-cover-king.glb', import.meta.url),
            'queen': new URL('@resources/3d-models/glb/duvet-cover-queen.glb', import.meta.url),
        }[option_size] : undefined
    }, [option_size])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial('duvet-product', elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}