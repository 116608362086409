import { useCallback, useContext, useEffect } from "react"
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function DeskMat(props: ProductModelProps) {
    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return {
            '9x8': new URL('@resources/3d-models/glb/desk-mat-9x8.glb', import.meta.url),
            '35x16': new URL('@resources/3d-models/glb/desk-mat-35x16.glb', import.meta.url),
        }[props.variant]
    }, [props.variant])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Hide Stretcher
    useEffect(() => {
        let model = FindObject("Scene Stretcher", elements)
        if (model) model.visible = false
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial(props.variant == "9x8" ? "mat_9" : "mat_35", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}