import { type ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ItemList from '../Common/ItemList/ItemList'
import { Button, ButtonBase, Container, Divider, Grid, MenuItem, Paper, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Circle as CircleIcon, RefreshRounded as RefreshRoundedIcon } from '@mui/icons-material'
import { type CartStatus } from '../UserData/_stores/UserCartStore'
import type UserCartStore from '../UserData/_stores/UserCartStore'
import { LIST_CART_STATUSES } from '../UserData/_stores/UserCartStore'
import SearchBar from '../Common/ItemList/SearchBar'
import { useParams, useSearchParams } from 'react-router-dom'
import SelectV2 from '../Common/Form/SelectV2'
import useOrderStatusProps from '../Common/_hooks/Order/useOrderStatusProps'
import OrderRow from '../Account/Orders/OrderRow'
import AccountHeader from '../Account/AccountHeader'
import { Link } from 'react-router-dom'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { CartStack } from '../Cart/CartContent/CartStack'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { FetchPurchaseReportData } from './_actions/DashboardActions'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import LabLoader from '../DesignLab/_components/LabLoader'

export default function DashboardPurchaseReport() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [tR] = useTranslation('routes')
  const theme = useTheme()
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const params = useParams<{ storeSlug: string }>();

  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('fetch_purchase_report_data'))

  const [startDate, setStartDate] = useState(DateTime.now().minus({ days: 30 }))
  const [endDate, setEndDate] = useState(DateTime.now())
  const [reportData, setReportData] = useState<Record<string, string>>({})

  const onChangeStartDate = useCallback((date: DateTime | null) => {
    if (!date) return
    setStartDate(date)
  }, [])

  const onChangeEndDate = useCallback((date: DateTime | null) => {
    if (!date) return
    setEndDate(date)
  }, [])

  useEffect(() => {
    if (!params.storeSlug) return;

    dispatch(FetchPurchaseReportData(params.storeSlug, startDate.toFormat('yyyy/LL/dd'), endDate.toFormat('yyyy/LL/dd')).set({
      onSuccess: (response) => {
        setReportData(response.data.report);
      }
    }))
  }, [params.storeSlug, startDate, endDate])

  return <Container style={{ padding: '0 0.75rem' }} maxWidth="sm">
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <AccountHeader
        title={t('Purchase report')}
      />
      <Stack spacing={3}>
        <Stack direction="row" spacing={2} alignItems="flex-end">
          <DatePicker
            label={t('Start date')}
            value={startDate}
            onChange={onChangeStartDate}
          />

          <DatePicker
            label={t('End date')}
            value={endDate}
            onChange={onChangeEndDate}
          />
        </Stack>
        <Paper elevation={0} sx={{ p: 3 }}>
          {loader ? <LabLoader /> : <>
            <Stack spacing={3} divider={<Divider />}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center" justifyContent="space-between">
                <Typography variant="h3">{t('Purchase report')}</Typography>
                <Button
                  variant="dashed"
                  color="secondary"
                  startIcon={<FileDownloadOutlinedIcon />}
                  component={Link}
                  to={tR('/d/{{store}}/purchase-report-pdf', { store: params.storeSlug }) + '?startDate=' + startDate.toFormat('yyyy/LL/dd') + '&endDate=' + endDate.toFormat('yyyy/LL/dd')}
                  target="_blank"
                >{t('Download (PDF)')}</Button>
              </Stack>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="body1">{t('Start date')}</Typography>
                  <Typography variant="h6">{startDate.toLocaleString(DateTime.DATE_MED)}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="body1">{t('End date')}</Typography>
                  <Typography variant="h6">{endDate.toLocaleString(DateTime.DATE_MED)}</Typography>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" sx={{ color: '#999' }}>{t('Subtotal')}</Typography>
                  <Typography variant="h6">$ {reportData.subtotal ?? '0.00'}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" sx={{ color: '#999' }}>{t('Discount')}</Typography>
                  <Typography variant="h6">($ {reportData.discount ?? '0.00'})</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" sx={{ color: '#999' }}>{t('Shipping')}</Typography>
                  <Typography variant="h6">$ {reportData.shipping ?? '0.00'}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="h6" sx={{ color: '#999' }}>{t('Taxes')}</Typography>
                  <Typography variant="h6">$ {reportData.taxes ?? '0.00'}</Typography>
                </Stack>
                {reportData.GST && reportData.GST !== '0.00' ? <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="body1" sx={{ color: '#999' }}>{t('GST')}</Typography>
                  <Typography variant="h6">$ {reportData.GST}</Typography>
                </Stack> : null}
                {reportData.QST && reportData.QST !== '0.00' ? <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="body1" sx={{ color: '#999' }}>{t('QST')}</Typography>
                  <Typography variant="h6">$ {reportData.QST}</Typography>
                </Stack> : null}
                {reportData.HST && reportData.HST !== '0.00' ? <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Typography variant="body1" sx={{ color: '#999' }}>{t('HST')}</Typography>
                  <Typography variant="h6">$ {reportData.HST}</Typography>
                </Stack> : null}
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Typography variant="h6" sx={{ color: '#999' }}>{t('TOTAL')}</Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>$ {reportData.total ?? '0.00'}</Typography>
              </Stack>
            </Stack>
            <CartStack
              direction={{ xs: 'column', sm: 'row' }}
              divider={<Divider
                orientation={breakpointSmDown ? 'horizontal' : 'vertical'}
                flexItem
              />}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: {
                  xs: 'center',
                  sm: 'left'
                }
              }}
              spacing={1}
            >
              <Typography variant="caption">
                Art of Where<br />
                5534 Ferrier<br />
                Mont-Royal, QC H4P 1M2, Canada
              </Typography>
              <Typography variant="caption">
                GST: 831650338 RT 0001
                <br />QST: 1219943063 TQ 0001
                <br />PST (SK): 7395783
                <br />PST (BC): 1443-9371
                <br />VAT (UK): 381 4991 65
              </Typography>
            </CartStack>
          </> }
        </Paper>
      </Stack>
    </LocalizationProvider>
  </Container>
}
