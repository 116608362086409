import { useContext, useEffect } from "react"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { FindMaterial } from "../../Common/Utils/ThreeTools"

export default function Pillow20x14(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/pillow20x14.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial('product', elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}