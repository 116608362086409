import { useCallback, useEffect, useState } from 'react'
import DialogButton from '../../Common/Buttons/DialogButton'
import Dialog from '../../Common/Dialog/Dialog'
import DialogActions from '../../Common/Dialog/DialogActions'
import DialogContent from '../../Common/Dialog/DialogContent'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { ApplyPromoCode } from '../../UserData/_actions/UserCartActions'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import UserRewardCoupon from '../../Account/GoalsAndRewards/UserRewardCoupon'
import type UserRewardStore from '../../UserData/_stores/UserRewardStore'
import { FetchUserRewards } from '../../UserData/_actions/UserRewardActions'
import { selectUnusedRewards } from '../../UserData/_selectors/UserRewardSelectors'

interface Props {
  cartIds: number[]
  opened: boolean
  onClose: () => void
}

export default function SelectRewardDialog(props: Props) {
  const [t] = useTranslation('cart')
  const dispatch = useAppDispatch()

  const userRewards = useAppSelector(selectUnusedRewards)
  const rewards = useAppSelector(state => state.get('appData').get('rewards'))

  const [selectedReward, setSelectedReward] = useState<number | undefined>(undefined)

  useEffect(() => {
    dispatch(FetchUserRewards())
  }, [])

  useEffect(() => {
    if (!props.opened) {
      setSelectedReward(undefined)
    }
  }, [props.opened])

  const onConfirm = useCallback(() => {
    const userReward = userRewards.get(String(selectedReward))
    if (!userReward) return

    dispatch(ApplyPromoCode(props.cartIds, userReward.get('code')).set({
      onSuccess: () => props.onClose()
    }))
  }, [props.onClose, props.cartIds, selectedReward, userRewards])

  const renderReward = useCallback((userReward: UserRewardStore) => {
    const reward = rewards.get(String(userReward.get('id_reward')))
    return reward ? <Grid key={userReward.get('id')} item xs={12} md={6}>
      <UserRewardCoupon
        reward={reward}
        onClick={() => setSelectedReward(userReward.get('id'))}
        selected={selectedReward === userReward.get('id')}
        fullWidth
      />
    </Grid> : null
  }, [selectedReward, rewards])

  return <Dialog
    open={props.opened}
    maxWidth="md"
    onClose={props.onClose}
    showCloseButton
    ornaments="shapes-2"
  >
    <DialogTitle>{t('Select your reward')}</DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">{t('The reward you select will be applied to your cart.')}</Typography>
        </Grid>
        {userRewards.valueSeq().map(renderReward)}
      </Grid>
    </DialogContent>
    <DialogActions>
      <DialogButton
        color="secondary"
        onClick={props.onClose}
      >{t('Cancel')}</DialogButton>
      <DialogButton
        color="primary"
        onClick={onConfirm}
        disabled={selectedReward === undefined}
      >{t('Confirm')}</DialogButton>
    </DialogActions>
  </Dialog>
}
