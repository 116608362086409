import { useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { DoubleSide, FrontSide } from "three"

export default function Headband(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/headband.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let out_mat = FindMaterial('design-out', elements)
        if(out_mat){
            out_mat.map = productTexture
            out_mat.map.flipY = false
            out_mat.map.needsUpdate = true
            out_mat.side = DoubleSide
        }

        let in_mat = FindMaterial('design-in', elements)
        if(in_mat){
            in_mat.transparent = true
            in_mat.opacity = 0.5
            in_mat.side = FrontSide
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}